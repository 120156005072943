/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ticketsTiersControllerCreate } from '../fn/ticket-tiers/tickets-tiers-controller-create';
import { TicketsTiersControllerCreate$Params } from '../fn/ticket-tiers/tickets-tiers-controller-create';
import { ticketsTiersControllerFindOne } from '../fn/ticket-tiers/tickets-tiers-controller-find-one';
import { TicketsTiersControllerFindOne$Params } from '../fn/ticket-tiers/tickets-tiers-controller-find-one';
import { ticketsTiersControllerGetAll } from '../fn/ticket-tiers/tickets-tiers-controller-get-all';
import { TicketsTiersControllerGetAll$Params } from '../fn/ticket-tiers/tickets-tiers-controller-get-all';
import { TierEntity } from '../models/tier-entity';

@Injectable({ providedIn: 'root' })
export class TicketTiersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `ticketsTiersControllerGetAll()` */
  static readonly TicketsTiersControllerGetAllPath = '/api/v1/tiers/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsTiersControllerGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsTiersControllerGetAll$Response(params: TicketsTiersControllerGetAll$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<TierEntity>;
}>> {
    return ticketsTiersControllerGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketsTiersControllerGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsTiersControllerGetAll(params: TicketsTiersControllerGetAll$Params, context?: HttpContext): Observable<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<TierEntity>;
}> {
    return this.ticketsTiersControllerGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<TierEntity>;
}>): {
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<TierEntity>;
} => r.body)
    );
  }

  /** Path part for operation `ticketsTiersControllerCreate()` */
  static readonly TicketsTiersControllerCreatePath = '/api/v1/tiers/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsTiersControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsTiersControllerCreate$Response(params: TicketsTiersControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<TierEntity>> {
    return ticketsTiersControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketsTiersControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ticketsTiersControllerCreate(params: TicketsTiersControllerCreate$Params, context?: HttpContext): Observable<TierEntity> {
    return this.ticketsTiersControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TierEntity>): TierEntity => r.body)
    );
  }

  /** Path part for operation `ticketsTiersControllerFindOne()` */
  static readonly TicketsTiersControllerFindOnePath = '/api/v1/tiers/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketsTiersControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsTiersControllerFindOne$Response(params: TicketsTiersControllerFindOne$Params, context?: HttpContext): Observable<StrictHttpResponse<TierEntity>> {
    return ticketsTiersControllerFindOne(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ticketsTiersControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketsTiersControllerFindOne(params: TicketsTiersControllerFindOne$Params, context?: HttpContext): Observable<TierEntity> {
    return this.ticketsTiersControllerFindOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<TierEntity>): TierEntity => r.body)
    );
  }

}
