import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject, OnInit
} from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import {
  TuiButton,
  TuiDialogContext,
  TuiIcon,
  TuiLabel, TuiLoader,
  TuiTextfieldOptionsDirective
} from '@taiga-ui/core';
import {  AddEventTicketTierForm } from '../../forms';
import { TUI_DATE_TIME_VALUE_TRANSFORMER, TuiCheckbox, tuiCreateTimePeriods } from '@taiga-ui/kit';
import { TicketTiersService } from '@core/api/services';
import { ReactiveFormsModule } from '@angular/forms';

import {
  TuiInputDateModule, TuiInputDateTimeModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputTimeModule,
  TuiSelectModule,
  TuiTextareaModule
} from '@taiga-ui/legacy';
import { BaseAbstractModal } from '../../../base.abstract.modal';
import { takeUntil } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TierEntity } from '@core/api/models/tier-entity';
import { DateTransformer } from '@core/utils';
import { TuiDay } from '@taiga-ui/cdk';

@Component({
  selector: 'event-ticket-tier-component',
  templateUrl: './event-ticket-tier.modal.html',
  styleUrls: ['./event-ticket-tier.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    TuiLabel,
    TuiCheckbox,
    TuiInputModule,
    TuiTextareaModule,
    TuiInputNumberModule,
    TuiSelectModule,
    TuiInputDateModule,
    TuiInputTimeModule,
    TuiTextfieldOptionsDirective,
    TuiButton,
    TuiIcon,
    TuiLoader,
    TuiInputDateTimeModule
  ],
  providers: [
    {
      provide: TUI_DATE_TIME_VALUE_TRANSFORMER,
      useClass: DateTransformer,
    },
  ],
  standalone: true
})
export class EventTicketTierModal extends BaseAbstractModal implements OnInit {
  public form: AddEventTicketTierForm;
  private ticketTiersService = inject(TicketTiersService);
  readonly currencies = ['USD', 'UAH', 'EUR'];
  public timePeriod = tuiCreateTimePeriods();
  readonly min = new TuiDay(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  readonly max = new TuiDay(2040, 2, 20);

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<
      boolean,
      {
        event_id: string;
        tier: TierEntity | null;
      }
    >
  ) {
    super();
    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  ngOnInit() {
    const { tier } = this.data;

    if (!tier) {
      this.form = new AddEventTicketTierForm();
      this.showLoader.set(false);
    }

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  get data(): {
    event_id: string;
    tier: TierEntity | null,
  } {
    return this.context.data;
  }

  public onSubmit() {
    this.showLoader.set(true);
    if (!this.form.value) {
      return;
    }

    const rawFormValue = this.form.getRawValue();

    this.ticketTiersService
      .ticketsTiersControllerCreate({
        eventId: this.data.event_id,
        body: {
          currency: rawFormValue.currency!,
          description: rawFormValue.description!,
          name: rawFormValue.name!,
          price: rawFormValue.price!,
          salesEndDate: this.toUtc(rawFormValue.salesEndDate),
          totalQuantity: rawFormValue.totalQuantity!,
          termless: rawFormValue.termless!,
          active: rawFormValue.active!
        }
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.showLoader.set(false))
      )
      .subscribe((res) => this.context.completeWith(true));
  }

  public onCancel() {
    this.context.completeWith(false);
  }

  toUtc(dateStr: any) {
    const [datePart, timePart] = dateStr.split(', ');

    const [day, month, year] = datePart.split('.').map(Number);

    const [hours, minutes] = timePart.split(':').map(Number);

    return new Date(Date.UTC(year, month - 1, day, hours, minutes)).toUTCString();
  }
}
