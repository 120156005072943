import { Component, inject, OnInit} from '@angular/core';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { AuthService } from '@core/services/auth.service';
import { takeUntil } from 'rxjs';
import { TuiLoader } from '@taiga-ui/core';
import { finalize } from 'rxjs/operators';
import { AuthCompanyService } from '@core/services/auth-company.service';

@Component({
  selector: 'confirm-redirect-page',
  template: `
    <tui-loader [overlay]='true' [showLoader]='showLoader()' />`,
  imports: [
    TuiLoader
  ],
  standalone: true
})
export class ConfirmRedirectPage extends BaseAbstractPage implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly authCompanyService = inject(AuthCompanyService);

  ngOnInit(): void {
    this.showLoader.set(true);
    // this.authService.signInThirdPartyCallback()
    //   .pipe(
    //     finalize(() => this.showLoader.set(false)),
    //     takeUntil(this.destroy$)
    //   ).subscribe(
    //   {
    //     next: async (res: any) => {
    //       if (this.authCompanyService.current()) {
    //         await this.router.navigateByUrl('/events');
    //         return;
    //       }
    //       await this.router.navigateByUrl('/companies');
    //     },
    //     error: (error) =>
    //       this.notificationService
    //         .open(error.message, { label: error.message || 'Error', appearance: 'error' })
    //         .pipe(takeUntil(this.destroy$))
    //         .subscribe()
    //   }
    // );

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

}
