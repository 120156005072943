import {
  Component,
  inject,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';
import { finalize, map } from 'rxjs/operators';
import { Observable, takeUntil } from 'rxjs';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { onImageError } from '@core/utils';
import { CompanyMemberEntity } from '@core/api/models/company-member-entity';
import { CompanyMemberService } from '@core/api/services';
import { PageHeaderComponent } from '@shared/page-header/page-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { TuiLoader, TuiSurface } from '@taiga-ui/core';
import { AsyncPipe } from '@angular/common';
import {
  TuiTableCell,
  TuiTableDirective,
  TuiTableTbody,
  TuiTableTd,
  TuiTableTh,
  TuiTableThGroup,
  TuiTableTr
} from '@taiga-ui/addon-table';
import { TuiAvatar, TuiPagination } from '@taiga-ui/kit';
import { TuiCardLarge } from '@taiga-ui/layout';

@Component({
  selector: 'member-list-page',
  templateUrl: './member-list.page.html',
  styleUrls: ['./member-list.page.scss'],
  imports: [
    PageHeaderComponent,
    TranslateModule,
    TuiLoader,
    AsyncPipe,
    TuiTableDirective,
    TuiTableTh,
    TuiTableThGroup,
    TuiTableTbody,
    TuiTableTr,
    TuiTableCell,
    TuiTableTd,
    TuiPagination,
    TuiAvatar,
    TuiCardLarge,
    TuiSurface
  ],
  standalone: true
})
export class MemberListPage extends BaseAbstractPage implements OnInit {
  displayedColumns: string[] = ['avatar', 'name', 'email', 'role', 'actions'];
  private companyMemberService = inject(CompanyMemberService);
  isLoading: WritableSignal<boolean> = signal(true);
  pageSize = 10;
  currentPage = 1;
  public onImgError = onImageError;

  public companyMembers$: Observable<{
    totalItems?: number;
    totalPages?: number;
    limit?: number;
    page?: number;
    items?: Array<CompanyMemberEntity>;
  }>;

  public pages(total: number) {
    return Math.ceil(total / this.pageSize);
  }

  async ngOnInit() {
    this.companyMembers$ = this.companyMemberService
      .companyMembersControllerGetAll({
        limit: 50,
        page: 1
      })
      .pipe(finalize(() => this.isLoading.set(false)));
    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  onSize(size: number): void {
    this.pageSize = size;
    // this.fetchData();
  }

  onPage(page: number): void {
    this.currentPage = page;
    // this.fetchData();
  }
}
