import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { EventEntity } from '@core/api/models/event-entity';
import { TuiProgressBar, TuiProgressSegmented } from '@taiga-ui/kit';
import { DatePipe, NgStyle, SlicePipe } from '@angular/common';
import { TuiCardLarge } from '@taiga-ui/layout';
import { TuiSurface } from '@taiga-ui/core';

@Component({
  selector: 'event-card-component',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TuiProgressBar,
    TuiProgressSegmented,
    DatePipe,
    NgStyle,
    TuiCardLarge,
    TuiSurface,
    SlicePipe
  ]
})
export class EventCardComponent {
  @Input() event: EventEntity;

  @Output() eventClick: EventEmitter<any> = new EventEmitter();
}
