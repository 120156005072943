<div tuiCardLarge tuiSurface="elevated">
  @if (eventForm) {
    <div [formGroup]="eventForm" class="space-y-10">
      <h1 class="tui-text_h1">{{ 'events#create#title' | translate }}</h1>
      <hr>

      <!-- General Info -->
      <div class="flex flex-col md:flex-row gap-6">
        <div class="md:w-1/2 space-y-2">
          <h2 class="tui-text_h2 mb-4">{{ 'events#create#lgeneral' | translate }}</h2>
          <p>{{ 'events#create#general_description' | translate }}</p>
        </div>
        <div class="md:w-1/2 space-y-4">
          <tui-input formControlName="title" class="mb-4">
            {{ 'events#create#name' | translate }}
            <input tuiTextfieldLegacy type="text" />
          </tui-input>
          <div formGroupName="eventInfo">
            <tui-input formControlName="publicTitle" class="mb-4">
              {{ 'events#create#public_name' | translate }}
              <input tuiTextfieldLegacy type="text" />
            </tui-input>
            <tui-textarea formControlName="description" class="mb-4">{{ 'events#create#description' | translate }}</tui-textarea>
          </div>
          <app-image-picker (selectImage)="pickImage($event)"></app-image-picker>
        </div>
      </div>

      <hr>

      <!-- Configuration -->
      <div class="flex flex-col md:flex-row gap-6">
        <div class="md:w-1/2 space-y-2">
          <h2 class="tui-text_h2 mb-4">{{ 'events#create#configure' | translate }}</h2>
          <p>{{ 'events#create#configure_description' | translate }}</p>
        </div>
        <div class="md:w-1/2 space-y-4">
          <!-- Type -->
          <tui-select
            tuiTextfieldSize="l"
            formControlName="type"
            [valueContent]="selectItemType"
          >
            {{ 'events#create#type' | translate }}
            <tui-data-list-wrapper
              *tuiDataList
              [items]="eventTypes"
              [itemContent]="selectItemType"
            ></tui-data-list-wrapper>
            <ng-template #selectItemType let-item>
              <span>{{ ('events#create#type_' + item) | translate }}</span>
            </ng-template>
          </tui-select>

          <!-- Status -->
          <tui-select
            tuiTextfieldSize="l"
            formControlName="status"
            [valueContent]="selectItemStatus"
          >
            {{ 'events#create#status' | translate }}
            <tui-data-list-wrapper
              *tuiDataList
              [items]="eventStatusTypes"
              [itemContent]="selectItemStatus"
            ></tui-data-list-wrapper>
            <ng-template #selectItemStatus let-item>
              <span>{{ ('events#create#status_' + item) | translate }}</span>
            </ng-template>
          </tui-select>

          <!-- Smart Tickets -->
          <tui-select
            tuiTextfieldSize="l"
            formControlName="smartTickets"
            [valueContent]="selectItemSmart"
          >
            {{ 'events#create#smart' | translate }}
            <tui-data-list-wrapper
              *tuiDataList
              [items]="smartTicketsTypes"
              [itemContent]="selectItemSmart"
            ></tui-data-list-wrapper>
            <ng-template #selectItemSmart let-item>
              <span>{{ ('events#create#smart_' + item) | translate }}</span>
            </ng-template>
          </tui-select>

          <!-- Age Rating -->
          <tui-input-number
            formControlName="ageRating"
            [min]="0"
            [max]="100"
          >
            {{ 'events#create#age' | translate }}
          </tui-input-number>
        </div>
      </div>

      <hr>

      <!-- Date & Time -->
      <div class="flex flex-col md:flex-row gap-6">
        <div class="md:w-1/2 space-y-2">
          <h2 class="tui-text_h2 mb-4">{{ 'events#create#date_time' | translate }}</h2>
          <p>{{ 'events#create#date_time_description' | translate }}</p>
        </div>
        <div class="md:w-1/2 space-y-4">
          <!-- Start -->
          <tui-input-date-time
            formControlName="startDateTime"
            [min]="min"
            [max]="max"
          >
            Choose date and time
            <input tuiTextfieldLegacy placeholder="OCT 26 1985 01:22" />
          </tui-input-date-time>

          <!-- End -->
          <tui-input-date-time
            formControlName="endDateTime"
            [min]="min"
            [max]="max"
          >
            Choose date and time end
            <input tuiTextfieldLegacy placeholder="OCT 26 1985 01:22" />
          </tui-input-date-time>
        </div>
      </div>

      <!-- Draft checkbox -->
      <div>
        <label tuiLabel class="inline-flex items-center space-x-2">
          <input
            tuiCheckbox
            type="checkbox"
            formControlName="draft"
            size="m"
          />
          <span>{{ 'events#create#draft' | translate }}</span>
        </label>
      </div>

      <hr>

      <!-- Buttons -->
      <div class="flex flex-col md:flex-row justify-between gap-4">
        <button
          tuiButton
          appearance="secondary"
          type="button"
          size="l"
          (click)="back()"
        >
          {{ 'common#cancel' | translate }}
        </button>

        <button
          tuiButton
          type="button"
          size="l"
          (click)="create()"
          [disabled]="eventForm.invalid"
        >
          {{ 'common#create' | translate }}
        </button>
      </div>
    </div>
  }
</div>
