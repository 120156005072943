<div class="container mx-auto px-4">
  <app-page-header
    [title]="'company#list#title' | translate"
    [description]="'company#list#description' | translate"
    [actionTitle]="'company#list#add_btn' | translate"
    (action)="router.navigate(['companies/create'])"
  ></app-page-header>

  <tui-loader
    [overlay]="true"
    [showLoader]="showLoader()"
  ></tui-loader>

  <div class="organizations-list grid grid-cols-1 sm:grid-cols-2 gap-4">
    @for (company of companies(); track company.id) {
      <div>
        <app-company-card
          [company]="company"
          (selectCompany)="selectCompany($event)"
          class="mb-5"
        ></app-company-card>
      </div>
    }
  </div>
</div>
