<app-page-header
  [title]="'company_member#list#title' | translate"
  [description]="'company_member#list#description' | translate"
  [actionTitle]="'company_member#list#add_new' | translate"
  (action)="router.navigateByUrl('members/create')"
></app-page-header>

<tui-loader [overlay]='true' [showLoader]='isLoading()'>
  @if (companyMembers$ | async; as members) {
    @if (members?.items.length !== 0) {
      <div tuiCardLarge tuiSurface="elevated">
        <table
          tuiTable
          class='table'
          [columns]='displayedColumns'
        >
          <thead>
          <tr tuiThGroup>
            <th
              tuiTh
              [resizable]='true'
            >
              {{ 'company_member#list#avatar' | translate }}
            </th>
            <th tuiTh>{{ 'company_member#list#name' | translate }}</th>
            <th tuiTh>{{ 'company_member#list#email' | translate }}</th>
            <th tuiTh>{{ 'company_member#list#role' | translate }}</th>
            <th tuiTh>{{ 'common#table#actions' | translate }}</th>
          </tr>
          </thead>
          <tbody
            tuiTbody
            [data]='members?.items'
          >
            @for (item of members?.items; track item) {
              <tr
                tuiTr
              >
                <td
                  *tuiCell="'avatar'"
                  tuiTd
                >
                  <tui-avatar size="s" src="@tui.user"></tui-avatar>
                </td>
                <td
                  *tuiCell="'name'"
                  tuiTd
                >
                  {{ item.user?.displayName || '-' }}
                </td>
                <td
                  *tuiCell="'email'"
                  tuiTd
                >
                  {{ item.user?.email || '-' }}
                </td>
                <td
                  *tuiCell="'role'"
                  tuiTd
                >
                  {{ item?.role || '-' }}
                </td>
                <td
                  *tuiCell="'actions'"
                  tuiTd
                >
                  -
                </td>
              </tr>
            }
          </tbody>
        </table>
        <tui-pagination
          [index]="currentPage - 1"
          [length]="pages(members?.totalPages)"
          (indexChange)="onPage($event)"
        ></tui-pagination>
      </div>
    }
  }

</tui-loader>
