import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnInit,
  Output, signal, WritableSignal
} from '@angular/core';
import { EventGeneralInfoForm } from '../../forms';
import { forkJoin, Observable, takeUntil } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { TuiButton, TuiIcon, TuiLoader, TuiSurface, TuiTitle } from '@taiga-ui/core';
import { EventInfoEntity } from '@core/api/models/event-info-entity';
import { EventService } from '@core/api/services';
import { ReactiveFormsModule } from '@angular/forms';
import {
  TuiInputModule,
  TuiTextareaModule
} from '@taiga-ui/legacy';
import { AsyncPipe } from '@angular/common';
import { ImagePickerComponent } from '@shared/image-picker/image-picker.component';
import { TuiCardLarge, TuiHeader } from '@taiga-ui/layout';
import { BaseAbstractComponent } from '../../../base.abstract.component';

@Component({
  selector: 'general-event-info-component',
  templateUrl: './general-event-info.component.html',
  styleUrls: ['./general-event-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    TuiInputModule,
    TuiTextareaModule,
    TuiButton,
    ImagePickerComponent,
    TuiCardLarge,
    TuiSurface,
    TuiLoader,
    TuiHeader,
    TuiTitle
  ],
  standalone: true
})
export class GeneralEventInfoComponent extends BaseAbstractComponent implements OnInit {
  @Input() info!: EventInfoEntity;
  @Output() updateEventInfo = new EventEmitter();
  private injector = inject(Injector);
  private eventService = inject(EventService);
  public form: EventGeneralInfoForm;

  public mainImage: WritableSignal<any> = signal(null);
  public backgroundImage: WritableSignal<any> = signal(null);

  ngOnInit(): void {
    this.showLoader.set(true);
    this.form = new EventGeneralInfoForm(
      {
        description: this.info.description,
        publicTitle: this.info.publicTitle
      },
      this.injector
    );
    this.showLoader.set(false);
    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  pickMainImageImage(image: any) {
    this.mainImage.set(image);
  }

  pickBackgroundImageImage(image: any) {
    this.backgroundImage.set(image);
  }

  onSubmit() {
    this.showLoader.set(true);
    const sources = [
      this.eventService.eventControllerUpdateGeneralInfo({
        eventId: this.info.id,
        body: this.form.value
      })
    ];

    if (this.mainImage()) {
      sources.push(
        this.eventService.eventControllerUpdateGeneralInfoMainImage({
          eventId: this.info.id,
          body: { image: this.mainImage() }
        })
      );
    }

    if (this.backgroundImage()) {
      sources.push(
        this.eventService.eventControllerUpdateGeneralInfoBackgroundImage({
          eventId: this.info.id,
          body: { image: this.backgroundImage() }
        })
      );
    }

    forkJoin(sources)
      .pipe(
        finalize(() => this.showLoader.set(false)),
        takeUntil(this.destroy$)
      )
      .subscribe({
      next: async (res) => {
        this.notificationService
          .open('Event successfully updated', {
            label: 'With a heading!',
            appearance: 'success'
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe();
        this.updateEventInfo.emit();
        console.log('success', res);
      },
      error: (error) => {
        this.notificationService
          .open('Cannot update event', {
            label: 'With a heading!',
            appearance: 'warning'
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe();
        console.log('error', error);
      }
    });
  }
}
