<div tuiCardLarge tuiSurface="elevated" class='event-card' (click)='eventClick.emit()'>
  <div class='img-holder' [ngStyle]="{'background-image': 'url(' + (event.info?.mainImg) + ')'}">
    <span class='status'>{{ event.status }}</span>
  </div>
  <div class='content'>
    <div class='tickets tui-space_bottom-1'>
      <div class="row">
        <div class='col-xs-6'>
          <h3>{{ event.info?.publicTitle }}</h3>
        </div>
      </div>
      <div class='row between-xs'>
        <div class='col-xs-6'>Tickets</div>
        <div
          class='col-xs-6 tui-island_text-align_right'>{{ (event.ticketsAmount - event.ticketsAmountVacant) || 0 }}
          / {{ event.ticketsAmount || 0 }}
        </div>
      </div>
      <progress tuiProgressBar
                [max]='event.ticketsAmount' [segments]="event.ticketsAmount"
                [value]='event.ticketsAmount - event.ticketsAmountVacant'
      ></progress>
    </div>
    <div class='date tui-space_bottom-6'>{{ event.startDateTime | date }} - {{ event.endDateTime | date }}</div>
    @if (event.info?.description) {
      <p>{{ event.info.description | slice: 0 : 50 }}...</p>
    }

  </div>
</div>
