import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { takeUntil } from 'rxjs';
import { CompanyInvitationsService } from '@core/api/services';
import { TuiButton } from '@taiga-ui/core';
import {
  TuiInputModule,
  TuiIslandDirective,
  TuiSelectModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/legacy';
import { PageHeaderComponent } from '@shared/page-header/page-header.component';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'member-company-create-page',
  templateUrl: './member-create.page.html',
  styleUrls: ['./member-create.page.scss'],
  standalone: true,
  imports: [
    TuiButton,
    TuiIslandDirective,
    PageHeaderComponent,
    TranslateModule,
    ReactiveFormsModule,
    TuiInputModule,
    TuiSelectModule,
    TuiTextfieldControllerModule
]
})
export class MemberCreatePage extends BaseAbstractPage implements OnInit {
  public inviteForm: FormGroup;
  private formBuilder = inject(FormBuilder);
  private companyInvitationsService = inject(CompanyInvitationsService);
  public companyMemberRoles = ['admin', 'manager'];

  async ngOnInit() {
    this.inviteForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      role: ['manager', [Validators.required]]
    });

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  async createRole() {
    await this.router.navigateByUrl('roles/create');
  }

  async sendInvite() {
    // const formValue = this.inviteForm.value;
    // this.companyInvitationsService
    //   .companyInvitationsControllerCreate({
    //     body: {
    //       email: formValue.email,
    //       role: formValue.role
    //     }
    //   })
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe({
    //     next: async (res: any) => {
    //       console.log(res);
    //       const result = JSON.parse(res);
    //       this.notificationService
    //         .open(result.message, {
    //           label: 'With a heading!',
    //           appearance: 'success'
    //         })
    //         .subscribe();
    //       await this.router.navigateByUrl('/events');
    //     },
    //     error: (error: any) => {
    //       this.notificationService
    //         .open('Cannot send invitation', {
    //           label: 'With a heading!',
    //           appearance: 'warning'
    //         })
    //         .subscribe();
    //     }
    //   });
  }
}
