import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CreatePaymentSettingsDto } from '@core/api/models/create-payment-settings-dto';
import { UpdatePaymentSettingsDto } from '@core/api/models/update-payment-settings-dto';

type PaymentSettingsFormDto = Partial<CreatePaymentSettingsDto> & UpdatePaymentSettingsDto;

export class PaymentSettingsForm extends FormGroup<{
  name: FormControl<string>;
  platform: FormControl<'liqpay'>;
  active: FormControl<boolean>;
  liqpaySettings: FormGroup<{
    privateKey: FormControl<string>;
    publicKey: FormControl<string>;
  }>;
}> {
  constructor(initialValue?: Partial<PaymentSettingsFormDto>) {
    super({
      name: new FormControl(initialValue?.name ?? '', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      platform: new FormControl(initialValue?.platform ?? 'liqpay', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      active: new FormControl(initialValue?.active ?? true, {
        nonNullable: true,
      }),
      liqpaySettings: new FormGroup({
        privateKey: new FormControl(initialValue?.liqpaySettings?.privateKey ?? '', {
          nonNullable: true,
          validators: [Validators.required],
        }),
        publicKey: new FormControl(initialValue?.liqpaySettings?.publicKey ?? '', {
          nonNullable: true,
          validators: [Validators.required],
        })
      }),
    });
  }
}
