import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormGroupDef } from '@core/utils';
import { CreateTierDto } from '@core/api/models/create-tier-dto';
import { CreateTierAttachmentDto } from '@core/api/models/create-tier-attachment-dto';

export class AddEventTicketTierForm extends FormGroup<
  FormGroupDef<Partial<CreateTierDto>>
> {
  constructor() {
    super({
      name: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required]
      }),
      description: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required]
      }),
      currency: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required]
      }),
      price: new FormControl(0, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      totalQuantity: new FormControl(0, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      termless: new FormControl(false, {
        nonNullable: true
      }),
      active: new FormControl(true, {
        nonNullable: true
      }),
      salesEndDate: new FormControl('', { nonNullable: true, validators: [Validators.required] })
    });
  }
}
