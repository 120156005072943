<tui-loader [overlay]="true" [showLoader]="showLoader()">
  @if (editForm) {
    <div tuiCardLarge tuiSurface="elevated" [formGroup]="editForm">

      <!-- Header Section -->
      <div class="flex flex-wrap mb-4">
        <div class="w-full">
          <h1 class="text-3xl font-bold">{{ 'company#edit_company' | translate }}</h1>
          <hr class="mt-2">
        </div>
      </div>

      <!-- Logo Section -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div>
          <h2 class="text-2xl font-semibold mb-4">{{ 'company#create_edit#logo#titile' | translate }}</h2>
          <p>{{ 'company#create_edit#logo#description' | translate }}</p>
        </div>
        <div>
          <app-image-picker
            [existImageUrl]="data.logoUrl"
            (selectImage)="pickImage($event)"
          ></app-image-picker>
        </div>
      </div>

      <!-- Information and Form Section -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div>
          <h2 class="text-2xl font-semibold mb-4">{{ 'company#create_edit#info#titile' | translate }}</h2>
          <p>{{ 'company#create_edit#info#description' | translate }}</p>
        </div>
        <div>
          <tui-input class="mb-4 field-large" formControlName="name">
            {{ 'company#form#name' | translate }}
            <input tuiTextfieldLegacy type="text" />
          </tui-input>
          <tui-input class="mb-4 field-large" formControlName="email">
            {{ 'company#form#email' | translate }}
            <input tuiTextfieldLegacy type="email" />
          </tui-input>
          <tui-input formControlName="specialization" class="mb-4 field-large">
            {{ 'company#form#specialization' | translate }}
          </tui-input>
        </div>
      </div>
      <hr class="mb-4">

      <!-- Action Buttons Section -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <button
            tuiButton
            type="button"
            appearance="destructive"
            (click)="deleteOrganization()"
            class="mr-3 mb-3"
          >
            {{ 'common#delete' | translate }}
          </button>
        </div>
        <div class="text-right">
          <button
            tuiButton
            type="button"
            appearance="outline"
            (click)="back()"
            class="mr-3 mb-3"
          >
            {{ 'common#cancel' | translate }}
          </button>
          <button
            tuiButton
            type="button"
            class="mr-3 mb-3"
            (click)="editOrganization()"
            [disabled]="editForm.invalid"
          >
            {{ 'common#save' | translate }}
          </button>
        </div>
      </div>

    </div>
  }
</tui-loader>
