import { Injectable } from '@angular/core';
import { TuiDay, TuiTime, TuiValueTransformer } from '@taiga-ui/cdk';

/**
 * This transformer converts between a TuiDay (the Taiga UI date type)
 * and a native JS Date in UTC.
 */
@Injectable()
export class DateTransformer extends TuiValueTransformer<
  [TuiDay | null, TuiTime | null] | null,
  string
> {
  private readonly separator = ', ';

  public fromControlValue(
    controlValue: string
  ): [TuiDay | null, TuiTime | null] | null {
    const [day, time = ''] = controlValue ? controlValue.split(this.separator) : '';

    return day
      ? [TuiDay.normalizeParse(day), time ? TuiTime.fromString(time) : null]
      : null;
  }

  public toControlValue([day, time]: [TuiDay | null, TuiTime | null]): string {
    return day
      ? day.toString() + (time ? `${this.separator}${time.toString()}` : '')
      : '';
  }
}
