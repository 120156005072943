<div class='modal-content'>
  <div class='description'>
    <div class='description_holder'>
      <div class='description_holder_icon'>
        <tui-icon icon='@tui.file-text'></tui-icon>
      </div>
    </div>
    <h3 class='tui-text_h3 tui-space_bottom-4'>{{ 'payment#create#titile' | translate }}</h3>
    <p> {{ 'payment#create#description' | translate }}</p>
  </div>
  <div class='form-holder'>
    <tui-loader [overlay]='true' [showLoader]='showLoader()'>
      @if (form) {
        <div [formGroup]='form'>
          <h2 class='tui-text_h2 tui-space_bottom-8'>{{ 'payment#create#titile' | translate }}</h2>
          <label tuiLabel class="tui-space_bottom-6">
            <input tuiCheckbox type="checkbox"
                   formControlName="active"
                   class='tui-space_bottom-4'
                   size="m"
            >
            {{ 'payment#form#active' | translate }}
          </label>
          <tui-input
            class='tui-space_bottom-4'
            formControlName='name'>
            {{ 'payment#form#name' | translate }}
          </tui-input>
          <tui-select
            formControlName='platform'
            class='tui-space_bottom-4'
          >
            {{ 'payment#form#platform' | translate }}
            <tui-data-list-wrapper
              *tuiDataList
              [items]='platforms'
            ></tui-data-list-wrapper>
          </tui-select>
          @if (form.controls.liqpaySettings) {
            <div formGroupName="liqpaySettings">
              <tui-input
                class='tui-space_bottom-4'
                formControlName='privateKey'>
                {{ 'payment#form#private_key' | translate }}
              </tui-input>
              <tui-input
                class='tui-space_bottom-4'
                formControlName='publicKey'>
                {{ 'payment#form#public_key' | translate }}
              </tui-input>
            </div>
          }
          <div class='modal-footer'>
            <button
              tuiButton
              type='button'
              appearance='secondary'
              (click)='onCancel()'
            >
              {{ 'common#cancel' | translate}}
            </button>
            <button
              tuiButton
              type='button'
              (click)='onSubmit()'
              [disabled]='form.invalid || showLoader()'
            >
              {{ 'common#save' | translate }}
            </button>
          </div>
        </div>
      }
    </tui-loader>
  </div>
</div>
