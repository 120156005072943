import { ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { CompanyService } from '@core/api/services';
import { of, switchMap, takeUntil } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CompanyEntity } from '@core/api/models/company-entity';
import { TuiButton, TuiIcon, TuiLoader, TuiSurface } from '@taiga-ui/core';
import { TuiInputModule } from '@taiga-ui/legacy';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { AuthCompanyService } from '@core/services/auth-company.service';
import { TuiCardLarge } from '@taiga-ui/layout';
import { ImagePickerComponent } from '@shared/image-picker/image-picker.component';

@Component({
  selector: 'company-edit-page',
  templateUrl: './company-edit.page.html',
  styleUrls: ['./company-edit.page.scss'],
  imports: [
    TuiLoader,
    ReactiveFormsModule,
    TuiButton,
    TranslateModule,
    TuiInputModule,
    TuiCardLarge,
    TuiSurface,
    ImagePickerComponent
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyEditPage extends BaseAbstractPage implements OnInit {
  public companyId: string;
  public editForm: FormGroup;
  private companiesService = inject(CompanyService);
  private authCompanyService = inject(AuthCompanyService);

  public image: WritableSignal<any> = signal(null);
  public data: CompanyEntity | null = null;

  ngOnInit(): void {
    this.showLoader.set(true);

    this.companiesService
      .companyControllerFindById({ id: this.route.snapshot.params.id })
      .pipe(finalize(() => this.showLoader.set(false)))
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.companyId = res.id;
          this.data = res;
          this.image.set(res.logoUrl);
          this.editForm = new FormGroup<any>({
            email: new FormControl(res.email || '', [Validators.required, Validators.email]),
            name: new FormControl(res.name || '', [Validators.required]),
            specialization: new FormControl(res.specialization || '', [Validators.required])
          });
        }
      });

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  get emailFormControl() {
    return this.editForm.get('email') as FormControl;
  }

  get nameFormControl() {
    return this.editForm.get('name') as FormControl;
  }

  get specializationFormControl() {
    return this.editForm.get('specialization') as FormControl;
  }

  pickImage(image: any) {
    this.image.set(image);
  }

  public editOrganization() {
    const data = {
      ...this.editForm.value
    };

    let update = this.companiesService
      .companyControllerUpdate({
        id: this.companyId,
        body: data
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.showLoader.set(false))
      );

    if (this.image()) {
      update = update.pipe(
        switchMap((res) =>
          res?.id
            ? this.companiesService.companyControllerUpdateLogo({
              id: this.companyId,
              body: { image: this.image() }
            })
            : of(res)
        )
      );
    }

    update
      .pipe(
        switchMap(() =>
          this.companiesService.companyControllerFindById({
            id: this.companyId
          })
        )
      )
      .subscribe({
        next: async (res) => {
          this.notificationService
            .open('Organization successfully created', {
              label: 'With a heading!',
              appearance: 'success'
            })
            .subscribe();
          if (res) {
            this.authCompanyService.set(res);
          }
          this.back();
          console.log('success', res);
        },
        error: (error) => {
          this.notificationService
            .open('Cannot create organization', {
              label: 'With a heading!',
              appearance: 'warning'
            })
            .subscribe();
          console.log('error', error);
        }
      });
  }

  deleteOrganization() {
  }
}
