import {
  enableProdMode,
  ErrorHandler,
  importProvidersFrom
} from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { CompanyInterceptor } from '@core/http-interceptors/company.interceptor';
import { ApiModule } from '@core/api/api.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app/app-routing.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AuthService } from '@core/services/auth.service';
import { AuthCompanyService } from '@core/services/auth-company.service';
import { ThemeService } from '@core/services/theme.service';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { AuthInterceptor } from '@core/http-interceptors/auth.interceptor';
import { AuthApiModule } from '@core/auth-api/auth-api.module';

if (environment.production) {
  enableProdMode();
}

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.i18nPrefix}/assets/i18n/`,
    '.json'
  );
}

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
    LocalStorageService,
    AuthService,
    AuthCompanyService,
    ThemeService,
    importProvidersFrom(
      ApiModule.forRoot({ rootUrl: environment.apiConfigs.API_URL }),
      AuthApiModule.forRoot({ rootUrl: environment.apiConfigs.AUTH_URL }),
      FormsModule,
      RouterModule,
      ReactiveFormsModule,
      AppRoutingModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CompanyInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi())
  ]
});
