/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CompanyInvitationEntity } from '../models/company-invitation-entity';
import { companyInvitationsControllerFindById } from '../fn/company-invitations/company-invitations-controller-find-by-id';
import { CompanyInvitationsControllerFindById$Params } from '../fn/company-invitations/company-invitations-controller-find-by-id';
import { companyInvitationsControllerRemove } from '../fn/company-invitations/company-invitations-controller-remove';
import { CompanyInvitationsControllerRemove$Params } from '../fn/company-invitations/company-invitations-controller-remove';

@Injectable({ providedIn: 'root' })
export class CompanyInvitationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `companyInvitationsControllerFindById()` */
  static readonly CompanyInvitationsControllerFindByIdPath = '/api/v1/company/invitations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyInvitationsControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyInvitationsControllerFindById$Response(params: CompanyInvitationsControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyInvitationEntity>> {
    return companyInvitationsControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyInvitationsControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyInvitationsControllerFindById(params: CompanyInvitationsControllerFindById$Params, context?: HttpContext): Observable<CompanyInvitationEntity> {
    return this.companyInvitationsControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyInvitationEntity>): CompanyInvitationEntity => r.body)
    );
  }

  /** Path part for operation `companyInvitationsControllerRemove()` */
  static readonly CompanyInvitationsControllerRemovePath = '/api/v1/company/invitations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyInvitationsControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyInvitationsControllerRemove$Response(params: CompanyInvitationsControllerRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<CompanyInvitationEntity>;
}>> {
    return companyInvitationsControllerRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyInvitationsControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyInvitationsControllerRemove(params: CompanyInvitationsControllerRemove$Params, context?: HttpContext): Observable<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<CompanyInvitationEntity>;
}> {
    return this.companyInvitationsControllerRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<CompanyInvitationEntity>;
}>): {
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<CompanyInvitationEntity>;
} => r.body)
    );
  }

}
