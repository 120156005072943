<div class='modal-content'>
  <div class='description'>
    <div class='description_holder'>
      <div class='description_holder_icon'>
        <tui-icon icon='@tui.file-text'></tui-icon>
      </div>
    </div>
    <h3 class='tui-text_h3 tui-space_bottom-4'>Що таке пункти програми і як створити повну програму?</h3>
    <p>
      Пункти програми це розділи розкладу вашого івенту. Наприклад з 17:00 до 18:00 у вас буде виступати гурт для
      розігріву глядачів, а з 18:00 до 20:00 хейдлайнери вашого концерту.
      <br>
      Кожен пункт програми потрібно додавати окрему для гармонічного відображення на сторінці вашого івенту.
      <br>
      Будь-лазка заповніть усі поля справа та натисніть створити, після чого пункт будет доданий до вашої програми.
    </p>
  </div>
  @if (form) {
    <div class='form-holder' [formGroup]='form'>
      <h2 class='tui-text_h2 tui-space_bottom-8'>Створити пункт програми</h2>
      <p>Картинка пункту (рекомендований розмір 256х256px)</p>
      <app-image-picker
        [existImageUrl]="data?.program?.imgUrl"
        (selectImage)="pickImage($event)"
      ></app-image-picker>
      <tui-input
        class='tui-space_bottom-4'
        formControlName='title'>
        Назва пункту програми
      </tui-input>
      <tui-textarea
        formControlName='shortDescription'
        class='tui-space_bottom-4 field-small'
        [maxLength]='30'
        [expandable]='true'
      >
        Short description
      </tui-textarea>
      <tui-textarea
        formControlName='description'
        class='tui-space_bottom-4 field-medium'
        [maxLength]='120'
        [expandable]='false'
      >
        Description
      </tui-textarea>
      <tui-input-time
        class='tui-space_bottom-4'
        formControlName='time'
        [tuiTextfieldCleaner]='true'
        [items]='timePeriod'
      >
        Time
      </tui-input-time>
      <div class='modal-footer'>
        <button
          tuiButton
          type='button'
          appearance='outline'
          (click)='onCancel()'
        >
          Cancel
        </button>
        <button
          tuiButton
          type='button'
          (click)='onSubmit()'
          [disabled]='form.invalid'
        >
          Save
        </button>
      </div>
    </div>
  }
</div>
