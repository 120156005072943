import {
  TuiInputDateRangeModule,
  TuiInputDateTimeModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputTimeModule,
  TuiSelectModule,
  TuiTextareaModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/legacy';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { of, switchMap, takeUntil } from 'rxjs';
import { TUI_DATE_TIME_VALUE_TRANSFORMER, TuiCheckbox, tuiCreateTimePeriods } from '@taiga-ui/kit';
import { TuiDay } from '@taiga-ui/cdk';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { EventService } from '@core/api/services';
import { TuiButton, TuiLabel, TuiSurface } from '@taiga-ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { ImagePickerComponent } from '@shared/image-picker/image-picker.component';
import { TuiCardLarge } from '@taiga-ui/layout';
import { finalize } from 'rxjs/operators';
import { AddEventForm } from '../../forms/add-event.form';
import { CreateEventDto } from '@core/api/models/create-event-dto';
import { DateTransformer } from '@core/utils';

@Component({
  selector: 'event-member-company-create-page',
  templateUrl: './event-create.page.html',
  styleUrls: ['./event-create.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TuiButton,
    ReactiveFormsModule,
    TranslateModule,
    TuiInputModule,
    TuiSelectModule,
    TuiTextfieldControllerModule,
    TuiInputDateRangeModule,
    TuiInputTimeModule,
    TuiInputNumberModule,
    TuiCheckbox,
    TuiLabel,
    ImagePickerComponent,
    TuiCardLarge,
    TuiSurface,
    TuiInputDateTimeModule,
    TuiTextareaModule
  ],
  providers: [
    {
      provide: TUI_DATE_TIME_VALUE_TRANSFORMER,
      useClass: DateTransformer,
    },
  ],
  standalone: true
})
export class EventCreatePage extends BaseAbstractPage implements OnInit {

  @Input() event?: any;
  public eventForm = new AddEventForm();
  private eventService = inject(EventService);

  public image: WritableSignal<any> = signal(null);

  public eventTypes = ['ONLINE', 'PHYSICAL'];
  public eventStatusTypes = ['STAND_BY' , 'PUBLIC'];
  public smartTicketsTypes = ['NONE' , 'ONLY' , 'ANY'];
  readonly min = new TuiDay(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  readonly max = new TuiDay(2040, 2, 20);

  async ngOnInit() {
    if (this.event) {
      this.eventForm.patchValue(this.event);
    }

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  pickImage(image: any) {
    this.image.set(image);
  }

  create() {
    this.showLoader.set(true);
    const rawFormValue = this.eventForm.getRawValue();

    const dto: CreateEventDto = {
      ...rawFormValue,
      eventInfo: {
        description: rawFormValue.eventInfo?.description ?? null,
        publicTitle: rawFormValue.eventInfo?.publicTitle ?? ''
      },
      ageRating: rawFormValue.ageRating ?? 0,
      ticketsAmount: rawFormValue.ticketsAmount ?? 0,
      ticketsAmountVacant: rawFormValue.ticketsAmountVacant ?? 0,
      isDraft: rawFormValue.isDraft ?? false,
      smartTickets: rawFormValue.smartTickets ?? 'NONE',
      status: rawFormValue.status ?? 'STAND_BY',
      type: rawFormValue.type ?? 'ONLINE',
      title: rawFormValue.title ?? '',
      tags: rawFormValue.tags ?? [],
      startDateTime: this.toUtc(rawFormValue.startDateTime) ,
      endDateTime: this.toUtc(rawFormValue.endDateTime),
    };

    return this.eventService
      .eventControllerCreate({
        body: dto
      })
      .pipe(
        switchMap((res) =>
          res?.id && this.image()
            ? this.eventService.eventControllerUpdateGeneralInfoMainImage({
              eventId: res.id,
              body: { image: this.image() }
            })
            : of(res)
        )
      )
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.showLoader.set(false);
        }))
      .subscribe({
        next: async (res) => {
          this.notificationService
            .open('Event successfully created', {
              label: 'With a heading!',
              appearance: 'success'
            })
            .subscribe();
          await this.router.navigateByUrl('/events');
          console.log('success', res);
        },
        error: (error) => {
          this.notificationService
            .open('Cannot create organization', {
              label: 'With a heading!',
              appearance: 'warning'
            })
            .subscribe();
          console.log('error', error);
        }
      });
  }

  toUtc(dateStr: any) {
    const [datePart, timePart] = dateStr.split(', ');

    const [day, month, year] = datePart.split('.').map(Number);

    const [hours, minutes] = timePart.split(':').map(Number);

    return new Date(Date.UTC(year, month - 1, day, hours, minutes)).toUTCString();
  }
}
