/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { authControllerGetMe } from '../fn/auth/auth-controller-get-me';
import { AuthControllerGetMe$Params } from '../fn/auth/auth-controller-get-me';
import { authControllerGoogleCallback } from '../fn/auth/auth-controller-google-callback';
import { AuthControllerGoogleCallback$Params } from '../fn/auth/auth-controller-google-callback';
import { authControllerGoogleLogin } from '../fn/auth/auth-controller-google-login';
import { AuthControllerGoogleLogin$Params } from '../fn/auth/auth-controller-google-login';
import { authControllerLogin } from '../fn/auth/auth-controller-login';
import { AuthControllerLogin$Params } from '../fn/auth/auth-controller-login';
import { authControllerLogout } from '../fn/auth/auth-controller-logout';
import { AuthControllerLogout$Params } from '../fn/auth/auth-controller-logout';
import { authControllerRefresh } from '../fn/auth/auth-controller-refresh';
import { AuthControllerRefresh$Params } from '../fn/auth/auth-controller-refresh';
import { authControllerRegister } from '../fn/auth/auth-controller-register';
import { AuthControllerRegister$Params } from '../fn/auth/auth-controller-register';

@Injectable({ providedIn: 'root' })
export class AuthApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authControllerGetMe()` */
  static readonly AuthControllerGetMePath = '/auth/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerGetMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerGetMe$Response(params?: AuthControllerGetMe$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerGetMe(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerGetMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerGetMe(params?: AuthControllerGetMe$Params, context?: HttpContext): Observable<void> {
    return this.authControllerGetMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerRegister()` */
  static readonly AuthControllerRegisterPath = '/auth/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRegister$Response(params: AuthControllerRegister$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerRegister(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRegister(params: AuthControllerRegister$Params, context?: HttpContext): Observable<void> {
    return this.authControllerRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerLogin()` */
  static readonly AuthControllerLoginPath = '/auth/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerLogin$Response(params: AuthControllerLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerLogin(params: AuthControllerLogin$Params, context?: HttpContext): Observable<void> {
    return this.authControllerLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerRefresh()` */
  static readonly AuthControllerRefreshPath = '/auth/refresh';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerRefresh()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRefresh$Response(params: AuthControllerRefresh$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerRefresh(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerRefresh$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRefresh(params: AuthControllerRefresh$Params, context?: HttpContext): Observable<void> {
    return this.authControllerRefresh$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerLogout()` */
  static readonly AuthControllerLogoutPath = '/auth/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerLogout$Response(params?: AuthControllerLogout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerLogout(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerLogout(params?: AuthControllerLogout$Params, context?: HttpContext): Observable<void> {
    return this.authControllerLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerGoogleLogin()` */
  static readonly AuthControllerGoogleLoginPath = '/auth/google';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerGoogleLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerGoogleLogin$Response(params?: AuthControllerGoogleLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerGoogleLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerGoogleLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerGoogleLogin(params?: AuthControllerGoogleLogin$Params, context?: HttpContext): Observable<void> {
    return this.authControllerGoogleLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authControllerGoogleCallback()` */
  static readonly AuthControllerGoogleCallbackPath = '/auth/google/callback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerGoogleCallback()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerGoogleCallback$Response(params?: AuthControllerGoogleCallback$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerGoogleCallback(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerGoogleCallback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerGoogleCallback(params?: AuthControllerGoogleCallback$Params, context?: HttpContext): Observable<void> {
    return this.authControllerGoogleCallback$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
