<header class="flex items-center p-header" [ngClass]="{'underline': underline}">
  <div class="w-2/3">
    @if (title) {
      <h1 class="header_title text-3xl font-bold">{{ title }}</h1>
    }
    @if (description) {
      <p class="mt-2">{{ description }}</p>
    }
  </div>
  @if (actionTitle && action) {
    <div class="w-1/3 text-right">
      <button
        tuiButton
        type="button"
        (click)="action.emit()"
      >
        {{ actionTitle }}
      </button>
    </div>
  }
</header>
