/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EventArtistEntity } from '../models/event-artist-entity';
import { EventCategoryEntity } from '../models/event-category-entity';
import { eventControllerCreate } from '../fn/event/event-controller-create';
import { EventControllerCreate$Params } from '../fn/event/event-controller-create';
import { eventControllerCreateArtist } from '../fn/event/event-controller-create-artist';
import { EventControllerCreateArtist$Params } from '../fn/event/event-controller-create-artist';
import { eventControllerCreateCategories } from '../fn/event/event-controller-create-categories';
import { EventControllerCreateCategories$Params } from '../fn/event/event-controller-create-categories';
import { eventControllerCreateLink } from '../fn/event/event-controller-create-link';
import { EventControllerCreateLink$Params } from '../fn/event/event-controller-create-link';
import { eventControllerCreatePartner } from '../fn/event/event-controller-create-partner';
import { EventControllerCreatePartner$Params } from '../fn/event/event-controller-create-partner';
import { eventControllerCreateProgram } from '../fn/event/event-controller-create-program';
import { EventControllerCreateProgram$Params } from '../fn/event/event-controller-create-program';
import { eventControllerDeleteArtist } from '../fn/event/event-controller-delete-artist';
import { EventControllerDeleteArtist$Params } from '../fn/event/event-controller-delete-artist';
import { eventControllerDeleteLink } from '../fn/event/event-controller-delete-link';
import { EventControllerDeleteLink$Params } from '../fn/event/event-controller-delete-link';
import { eventControllerDeletePartner } from '../fn/event/event-controller-delete-partner';
import { EventControllerDeletePartner$Params } from '../fn/event/event-controller-delete-partner';
import { eventControllerDeleteProgram } from '../fn/event/event-controller-delete-program';
import { EventControllerDeleteProgram$Params } from '../fn/event/event-controller-delete-program';
import { eventControllerFindAllArtistsByEventId } from '../fn/event/event-controller-find-all-artists-by-event-id';
import { EventControllerFindAllArtistsByEventId$Params } from '../fn/event/event-controller-find-all-artists-by-event-id';
import { eventControllerFindAllCategoriesByEventId } from '../fn/event/event-controller-find-all-categories-by-event-id';
import { EventControllerFindAllCategoriesByEventId$Params } from '../fn/event/event-controller-find-all-categories-by-event-id';
import { eventControllerFindAllLinksByEventId } from '../fn/event/event-controller-find-all-links-by-event-id';
import { EventControllerFindAllLinksByEventId$Params } from '../fn/event/event-controller-find-all-links-by-event-id';
import { eventControllerFindAllPartnersByEventId } from '../fn/event/event-controller-find-all-partners-by-event-id';
import { EventControllerFindAllPartnersByEventId$Params } from '../fn/event/event-controller-find-all-partners-by-event-id';
import { eventControllerFindAllProgramsByEventId } from '../fn/event/event-controller-find-all-programs-by-event-id';
import { EventControllerFindAllProgramsByEventId$Params } from '../fn/event/event-controller-find-all-programs-by-event-id';
import { eventControllerFindArtistById } from '../fn/event/event-controller-find-artist-by-id';
import { EventControllerFindArtistById$Params } from '../fn/event/event-controller-find-artist-by-id';
import { eventControllerFindByCompany } from '../fn/event/event-controller-find-by-company';
import { EventControllerFindByCompany$Params } from '../fn/event/event-controller-find-by-company';
import { eventControllerFindById } from '../fn/event/event-controller-find-by-id';
import { EventControllerFindById$Params } from '../fn/event/event-controller-find-by-id';
import { eventControllerFindCategoryById } from '../fn/event/event-controller-find-category-by-id';
import { EventControllerFindCategoryById$Params } from '../fn/event/event-controller-find-category-by-id';
import { eventControllerFindGeneralInfoByEventId } from '../fn/event/event-controller-find-general-info-by-event-id';
import { EventControllerFindGeneralInfoByEventId$Params } from '../fn/event/event-controller-find-general-info-by-event-id';
import { eventControllerFindGeneralInfoById } from '../fn/event/event-controller-find-general-info-by-id';
import { EventControllerFindGeneralInfoById$Params } from '../fn/event/event-controller-find-general-info-by-id';
import { eventControllerFindLinkById } from '../fn/event/event-controller-find-link-by-id';
import { EventControllerFindLinkById$Params } from '../fn/event/event-controller-find-link-by-id';
import { eventControllerFindPartnerById } from '../fn/event/event-controller-find-partner-by-id';
import { EventControllerFindPartnerById$Params } from '../fn/event/event-controller-find-partner-by-id';
import { eventControllerFindProgramById } from '../fn/event/event-controller-find-program-by-id';
import { EventControllerFindProgramById$Params } from '../fn/event/event-controller-find-program-by-id';
import { eventControllerUpdate } from '../fn/event/event-controller-update';
import { EventControllerUpdate$Params } from '../fn/event/event-controller-update';
import { eventControllerUpdateArtist } from '../fn/event/event-controller-update-artist';
import { EventControllerUpdateArtist$Params } from '../fn/event/event-controller-update-artist';
import { eventControllerUpdateArtistImage } from '../fn/event/event-controller-update-artist-image';
import { EventControllerUpdateArtistImage$Params } from '../fn/event/event-controller-update-artist-image';
import { eventControllerUpdateCategory } from '../fn/event/event-controller-update-category';
import { EventControllerUpdateCategory$Params } from '../fn/event/event-controller-update-category';
import { eventControllerUpdateGeneralInfo } from '../fn/event/event-controller-update-general-info';
import { EventControllerUpdateGeneralInfo$Params } from '../fn/event/event-controller-update-general-info';
import { eventControllerUpdateGeneralInfoBackgroundImage } from '../fn/event/event-controller-update-general-info-background-image';
import { EventControllerUpdateGeneralInfoBackgroundImage$Params } from '../fn/event/event-controller-update-general-info-background-image';
import { eventControllerUpdateGeneralInfoMainImage } from '../fn/event/event-controller-update-general-info-main-image';
import { EventControllerUpdateGeneralInfoMainImage$Params } from '../fn/event/event-controller-update-general-info-main-image';
import { eventControllerUpdateLink } from '../fn/event/event-controller-update-link';
import { EventControllerUpdateLink$Params } from '../fn/event/event-controller-update-link';
import { eventControllerUpdatePartner } from '../fn/event/event-controller-update-partner';
import { EventControllerUpdatePartner$Params } from '../fn/event/event-controller-update-partner';
import { eventControllerUpdatePartnerImage } from '../fn/event/event-controller-update-partner-image';
import { EventControllerUpdatePartnerImage$Params } from '../fn/event/event-controller-update-partner-image';
import { eventControllerUpdateProgram } from '../fn/event/event-controller-update-program';
import { EventControllerUpdateProgram$Params } from '../fn/event/event-controller-update-program';
import { eventControllerUpdateProgramImage } from '../fn/event/event-controller-update-program-image';
import { EventControllerUpdateProgramImage$Params } from '../fn/event/event-controller-update-program-image';
import { EventEntity } from '../models/event-entity';
import { EventInfoEntity } from '../models/event-info-entity';
import { EventLinkEntity } from '../models/event-link-entity';
import { EventPartnerEntity } from '../models/event-partner-entity';
import { EventProgramEntity } from '../models/event-program-entity';

@Injectable({ providedIn: 'root' })
export class EventService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `eventControllerFindByCompany()` */
  static readonly EventControllerFindByCompanyPath = '/api/v1/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindByCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindByCompany$Response(params: EventControllerFindByCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventEntity>;
}>> {
    return eventControllerFindByCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindByCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindByCompany(params: EventControllerFindByCompany$Params, context?: HttpContext): Observable<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventEntity>;
}> {
    return this.eventControllerFindByCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventEntity>;
}>): {
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventEntity>;
} => r.body)
    );
  }

  /** Path part for operation `eventControllerCreate()` */
  static readonly EventControllerCreatePath = '/api/v1/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerCreate$Response(params: EventControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<EventEntity>> {
    return eventControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerCreate(params: EventControllerCreate$Params, context?: HttpContext): Observable<EventEntity> {
    return this.eventControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventEntity>): EventEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerFindById()` */
  static readonly EventControllerFindByIdPath = '/api/v1/events/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindById$Response(params: EventControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<EventEntity>> {
    return eventControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindById(params: EventControllerFindById$Params, context?: HttpContext): Observable<EventEntity> {
    return this.eventControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventEntity>): EventEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerUpdate()` */
  static readonly EventControllerUpdatePath = '/api/v1/events/{eventId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdate$Response(params: EventControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<EventEntity>> {
    return eventControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdate(params: EventControllerUpdate$Params, context?: HttpContext): Observable<EventEntity> {
    return this.eventControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventEntity>): EventEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerFindGeneralInfoByEventId()` */
  static readonly EventControllerFindGeneralInfoByEventIdPath = '/api/v1/events/{eventId}/general';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindGeneralInfoByEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindGeneralInfoByEventId$Response(params: EventControllerFindGeneralInfoByEventId$Params, context?: HttpContext): Observable<StrictHttpResponse<EventInfoEntity>> {
    return eventControllerFindGeneralInfoByEventId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindGeneralInfoByEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindGeneralInfoByEventId(params: EventControllerFindGeneralInfoByEventId$Params, context?: HttpContext): Observable<EventInfoEntity> {
    return this.eventControllerFindGeneralInfoByEventId$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventInfoEntity>): EventInfoEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerUpdateGeneralInfo()` */
  static readonly EventControllerUpdateGeneralInfoPath = '/api/v1/events/{eventId}/general';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerUpdateGeneralInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdateGeneralInfo$Response(params: EventControllerUpdateGeneralInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<EventInfoEntity>> {
    return eventControllerUpdateGeneralInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerUpdateGeneralInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdateGeneralInfo(params: EventControllerUpdateGeneralInfo$Params, context?: HttpContext): Observable<EventInfoEntity> {
    return this.eventControllerUpdateGeneralInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventInfoEntity>): EventInfoEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerFindGeneralInfoById()` */
  static readonly EventControllerFindGeneralInfoByIdPath = '/api/v1/events/general/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindGeneralInfoById()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindGeneralInfoById$Response(params: EventControllerFindGeneralInfoById$Params, context?: HttpContext): Observable<StrictHttpResponse<EventInfoEntity>> {
    return eventControllerFindGeneralInfoById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindGeneralInfoById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindGeneralInfoById(params: EventControllerFindGeneralInfoById$Params, context?: HttpContext): Observable<EventInfoEntity> {
    return this.eventControllerFindGeneralInfoById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventInfoEntity>): EventInfoEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerUpdateGeneralInfoBackgroundImage()` */
  static readonly EventControllerUpdateGeneralInfoBackgroundImagePath = '/api/v1/events/{eventId}/general/background-img';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerUpdateGeneralInfoBackgroundImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  eventControllerUpdateGeneralInfoBackgroundImage$Response(params: EventControllerUpdateGeneralInfoBackgroundImage$Params, context?: HttpContext): Observable<StrictHttpResponse<EventInfoEntity>> {
    return eventControllerUpdateGeneralInfoBackgroundImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerUpdateGeneralInfoBackgroundImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  eventControllerUpdateGeneralInfoBackgroundImage(params: EventControllerUpdateGeneralInfoBackgroundImage$Params, context?: HttpContext): Observable<EventInfoEntity> {
    return this.eventControllerUpdateGeneralInfoBackgroundImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventInfoEntity>): EventInfoEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerUpdateGeneralInfoMainImage()` */
  static readonly EventControllerUpdateGeneralInfoMainImagePath = '/api/v1/events/{eventId}/general/main-img';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerUpdateGeneralInfoMainImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  eventControllerUpdateGeneralInfoMainImage$Response(params: EventControllerUpdateGeneralInfoMainImage$Params, context?: HttpContext): Observable<StrictHttpResponse<EventInfoEntity>> {
    return eventControllerUpdateGeneralInfoMainImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerUpdateGeneralInfoMainImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  eventControllerUpdateGeneralInfoMainImage(params: EventControllerUpdateGeneralInfoMainImage$Params, context?: HttpContext): Observable<EventInfoEntity> {
    return this.eventControllerUpdateGeneralInfoMainImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventInfoEntity>): EventInfoEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerFindAllArtistsByEventId()` */
  static readonly EventControllerFindAllArtistsByEventIdPath = '/api/v1/events/{eventId}/artists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindAllArtistsByEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindAllArtistsByEventId$Response(params: EventControllerFindAllArtistsByEventId$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventArtistEntity>;
}>> {
    return eventControllerFindAllArtistsByEventId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindAllArtistsByEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindAllArtistsByEventId(params: EventControllerFindAllArtistsByEventId$Params, context?: HttpContext): Observable<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventArtistEntity>;
}> {
    return this.eventControllerFindAllArtistsByEventId$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventArtistEntity>;
}>): {
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventArtistEntity>;
} => r.body)
    );
  }

  /** Path part for operation `eventControllerCreateArtist()` */
  static readonly EventControllerCreateArtistPath = '/api/v1/events/{eventId}/artists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerCreateArtist()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerCreateArtist$Response(params: EventControllerCreateArtist$Params, context?: HttpContext): Observable<StrictHttpResponse<EventArtistEntity>> {
    return eventControllerCreateArtist(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerCreateArtist$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerCreateArtist(params: EventControllerCreateArtist$Params, context?: HttpContext): Observable<EventArtistEntity> {
    return this.eventControllerCreateArtist$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventArtistEntity>): EventArtistEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerFindArtistById()` */
  static readonly EventControllerFindArtistByIdPath = '/api/v1/events/artists/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindArtistById()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindArtistById$Response(params: EventControllerFindArtistById$Params, context?: HttpContext): Observable<StrictHttpResponse<EventArtistEntity>> {
    return eventControllerFindArtistById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindArtistById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindArtistById(params: EventControllerFindArtistById$Params, context?: HttpContext): Observable<EventArtistEntity> {
    return this.eventControllerFindArtistById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventArtistEntity>): EventArtistEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerDeleteArtist()` */
  static readonly EventControllerDeleteArtistPath = '/api/v1/events/artists/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerDeleteArtist()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerDeleteArtist$Response(params: EventControllerDeleteArtist$Params, context?: HttpContext): Observable<StrictHttpResponse<EventArtistEntity>> {
    return eventControllerDeleteArtist(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerDeleteArtist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerDeleteArtist(params: EventControllerDeleteArtist$Params, context?: HttpContext): Observable<EventArtistEntity> {
    return this.eventControllerDeleteArtist$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventArtistEntity>): EventArtistEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerUpdateArtist()` */
  static readonly EventControllerUpdateArtistPath = '/api/v1/events/artists/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerUpdateArtist()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdateArtist$Response(params: EventControllerUpdateArtist$Params, context?: HttpContext): Observable<StrictHttpResponse<EventArtistEntity>> {
    return eventControllerUpdateArtist(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerUpdateArtist$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdateArtist(params: EventControllerUpdateArtist$Params, context?: HttpContext): Observable<EventArtistEntity> {
    return this.eventControllerUpdateArtist$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventArtistEntity>): EventArtistEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerUpdateArtistImage()` */
  static readonly EventControllerUpdateArtistImagePath = '/api/v1/events/artists/{id}/image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerUpdateArtistImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  eventControllerUpdateArtistImage$Response(params: EventControllerUpdateArtistImage$Params, context?: HttpContext): Observable<StrictHttpResponse<EventArtistEntity>> {
    return eventControllerUpdateArtistImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerUpdateArtistImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  eventControllerUpdateArtistImage(params: EventControllerUpdateArtistImage$Params, context?: HttpContext): Observable<EventArtistEntity> {
    return this.eventControllerUpdateArtistImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventArtistEntity>): EventArtistEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerFindAllCategoriesByEventId()` */
  static readonly EventControllerFindAllCategoriesByEventIdPath = '/api/v1/events/{eventId}/categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindAllCategoriesByEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindAllCategoriesByEventId$Response(params: EventControllerFindAllCategoriesByEventId$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventCategoryEntity>;
}>> {
    return eventControllerFindAllCategoriesByEventId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindAllCategoriesByEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindAllCategoriesByEventId(params: EventControllerFindAllCategoriesByEventId$Params, context?: HttpContext): Observable<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventCategoryEntity>;
}> {
    return this.eventControllerFindAllCategoriesByEventId$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventCategoryEntity>;
}>): {
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventCategoryEntity>;
} => r.body)
    );
  }

  /** Path part for operation `eventControllerCreateCategories()` */
  static readonly EventControllerCreateCategoriesPath = '/api/v1/events/{eventId}/categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerCreateCategories()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerCreateCategories$Response(params: EventControllerCreateCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<EventCategoryEntity>> {
    return eventControllerCreateCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerCreateCategories$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerCreateCategories(params: EventControllerCreateCategories$Params, context?: HttpContext): Observable<EventCategoryEntity> {
    return this.eventControllerCreateCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventCategoryEntity>): EventCategoryEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerFindCategoryById()` */
  static readonly EventControllerFindCategoryByIdPath = '/api/v1/events/categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindCategoryById()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindCategoryById$Response(params: EventControllerFindCategoryById$Params, context?: HttpContext): Observable<StrictHttpResponse<EventCategoryEntity>> {
    return eventControllerFindCategoryById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindCategoryById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindCategoryById(params: EventControllerFindCategoryById$Params, context?: HttpContext): Observable<EventCategoryEntity> {
    return this.eventControllerFindCategoryById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventCategoryEntity>): EventCategoryEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerUpdateCategory()` */
  static readonly EventControllerUpdateCategoryPath = '/api/v1/events/categories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerUpdateCategory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdateCategory$Response(params: EventControllerUpdateCategory$Params, context?: HttpContext): Observable<StrictHttpResponse<EventCategoryEntity>> {
    return eventControllerUpdateCategory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerUpdateCategory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdateCategory(params: EventControllerUpdateCategory$Params, context?: HttpContext): Observable<EventCategoryEntity> {
    return this.eventControllerUpdateCategory$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventCategoryEntity>): EventCategoryEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerFindAllLinksByEventId()` */
  static readonly EventControllerFindAllLinksByEventIdPath = '/api/v1/events/{eventId}/links';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindAllLinksByEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindAllLinksByEventId$Response(params: EventControllerFindAllLinksByEventId$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventLinkEntity>;
}>> {
    return eventControllerFindAllLinksByEventId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindAllLinksByEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindAllLinksByEventId(params: EventControllerFindAllLinksByEventId$Params, context?: HttpContext): Observable<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventLinkEntity>;
}> {
    return this.eventControllerFindAllLinksByEventId$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventLinkEntity>;
}>): {
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventLinkEntity>;
} => r.body)
    );
  }

  /** Path part for operation `eventControllerCreateLink()` */
  static readonly EventControllerCreateLinkPath = '/api/v1/events/{eventId}/links';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerCreateLink()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerCreateLink$Response(params: EventControllerCreateLink$Params, context?: HttpContext): Observable<StrictHttpResponse<EventLinkEntity>> {
    return eventControllerCreateLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerCreateLink$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerCreateLink(params: EventControllerCreateLink$Params, context?: HttpContext): Observable<EventLinkEntity> {
    return this.eventControllerCreateLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventLinkEntity>): EventLinkEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerFindLinkById()` */
  static readonly EventControllerFindLinkByIdPath = '/api/v1/events/links/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindLinkById()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindLinkById$Response(params: EventControllerFindLinkById$Params, context?: HttpContext): Observable<StrictHttpResponse<EventLinkEntity>> {
    return eventControllerFindLinkById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindLinkById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindLinkById(params: EventControllerFindLinkById$Params, context?: HttpContext): Observable<EventLinkEntity> {
    return this.eventControllerFindLinkById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventLinkEntity>): EventLinkEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerDeleteLink()` */
  static readonly EventControllerDeleteLinkPath = '/api/v1/events/links/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerDeleteLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerDeleteLink$Response(params: EventControllerDeleteLink$Params, context?: HttpContext): Observable<StrictHttpResponse<EventLinkEntity>> {
    return eventControllerDeleteLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerDeleteLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerDeleteLink(params: EventControllerDeleteLink$Params, context?: HttpContext): Observable<EventLinkEntity> {
    return this.eventControllerDeleteLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventLinkEntity>): EventLinkEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerUpdateLink()` */
  static readonly EventControllerUpdateLinkPath = '/api/v1/events/links/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerUpdateLink()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdateLink$Response(params: EventControllerUpdateLink$Params, context?: HttpContext): Observable<StrictHttpResponse<EventLinkEntity>> {
    return eventControllerUpdateLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerUpdateLink$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdateLink(params: EventControllerUpdateLink$Params, context?: HttpContext): Observable<EventLinkEntity> {
    return this.eventControllerUpdateLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventLinkEntity>): EventLinkEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerFindAllPartnersByEventId()` */
  static readonly EventControllerFindAllPartnersByEventIdPath = '/api/v1/events/{eventId}/partners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindAllPartnersByEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindAllPartnersByEventId$Response(params: EventControllerFindAllPartnersByEventId$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventPartnerEntity>;
}>> {
    return eventControllerFindAllPartnersByEventId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindAllPartnersByEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindAllPartnersByEventId(params: EventControllerFindAllPartnersByEventId$Params, context?: HttpContext): Observable<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventPartnerEntity>;
}> {
    return this.eventControllerFindAllPartnersByEventId$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventPartnerEntity>;
}>): {
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventPartnerEntity>;
} => r.body)
    );
  }

  /** Path part for operation `eventControllerCreatePartner()` */
  static readonly EventControllerCreatePartnerPath = '/api/v1/events/{eventId}/partners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerCreatePartner()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerCreatePartner$Response(params: EventControllerCreatePartner$Params, context?: HttpContext): Observable<StrictHttpResponse<EventPartnerEntity>> {
    return eventControllerCreatePartner(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerCreatePartner$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerCreatePartner(params: EventControllerCreatePartner$Params, context?: HttpContext): Observable<EventPartnerEntity> {
    return this.eventControllerCreatePartner$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventPartnerEntity>): EventPartnerEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerFindPartnerById()` */
  static readonly EventControllerFindPartnerByIdPath = '/api/v1/events/partners/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindPartnerById()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindPartnerById$Response(params: EventControllerFindPartnerById$Params, context?: HttpContext): Observable<StrictHttpResponse<EventPartnerEntity>> {
    return eventControllerFindPartnerById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindPartnerById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindPartnerById(params: EventControllerFindPartnerById$Params, context?: HttpContext): Observable<EventPartnerEntity> {
    return this.eventControllerFindPartnerById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventPartnerEntity>): EventPartnerEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerDeletePartner()` */
  static readonly EventControllerDeletePartnerPath = '/api/v1/events/partners/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerDeletePartner()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerDeletePartner$Response(params: EventControllerDeletePartner$Params, context?: HttpContext): Observable<StrictHttpResponse<EventPartnerEntity>> {
    return eventControllerDeletePartner(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerDeletePartner$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerDeletePartner(params: EventControllerDeletePartner$Params, context?: HttpContext): Observable<EventPartnerEntity> {
    return this.eventControllerDeletePartner$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventPartnerEntity>): EventPartnerEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerUpdatePartner()` */
  static readonly EventControllerUpdatePartnerPath = '/api/v1/events/partners/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerUpdatePartner()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdatePartner$Response(params: EventControllerUpdatePartner$Params, context?: HttpContext): Observable<StrictHttpResponse<EventPartnerEntity>> {
    return eventControllerUpdatePartner(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerUpdatePartner$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdatePartner(params: EventControllerUpdatePartner$Params, context?: HttpContext): Observable<EventPartnerEntity> {
    return this.eventControllerUpdatePartner$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventPartnerEntity>): EventPartnerEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerUpdatePartnerImage()` */
  static readonly EventControllerUpdatePartnerImagePath = '/api/v1/events/partners/{id}/image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerUpdatePartnerImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  eventControllerUpdatePartnerImage$Response(params: EventControllerUpdatePartnerImage$Params, context?: HttpContext): Observable<StrictHttpResponse<EventPartnerEntity>> {
    return eventControllerUpdatePartnerImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerUpdatePartnerImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  eventControllerUpdatePartnerImage(params: EventControllerUpdatePartnerImage$Params, context?: HttpContext): Observable<EventPartnerEntity> {
    return this.eventControllerUpdatePartnerImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventPartnerEntity>): EventPartnerEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerFindAllProgramsByEventId()` */
  static readonly EventControllerFindAllProgramsByEventIdPath = '/api/v1/events/{eventId}/programs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindAllProgramsByEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindAllProgramsByEventId$Response(params: EventControllerFindAllProgramsByEventId$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventProgramEntity>;
}>> {
    return eventControllerFindAllProgramsByEventId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindAllProgramsByEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindAllProgramsByEventId(params: EventControllerFindAllProgramsByEventId$Params, context?: HttpContext): Observable<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventProgramEntity>;
}> {
    return this.eventControllerFindAllProgramsByEventId$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventProgramEntity>;
}>): {
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventProgramEntity>;
} => r.body)
    );
  }

  /** Path part for operation `eventControllerCreateProgram()` */
  static readonly EventControllerCreateProgramPath = '/api/v1/events/{eventId}/programs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerCreateProgram()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerCreateProgram$Response(params: EventControllerCreateProgram$Params, context?: HttpContext): Observable<StrictHttpResponse<EventProgramEntity>> {
    return eventControllerCreateProgram(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerCreateProgram$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerCreateProgram(params: EventControllerCreateProgram$Params, context?: HttpContext): Observable<EventProgramEntity> {
    return this.eventControllerCreateProgram$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventProgramEntity>): EventProgramEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerFindProgramById()` */
  static readonly EventControllerFindProgramByIdPath = '/api/v1/events/programs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerFindProgramById()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindProgramById$Response(params: EventControllerFindProgramById$Params, context?: HttpContext): Observable<StrictHttpResponse<EventProgramEntity>> {
    return eventControllerFindProgramById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerFindProgramById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerFindProgramById(params: EventControllerFindProgramById$Params, context?: HttpContext): Observable<EventProgramEntity> {
    return this.eventControllerFindProgramById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventProgramEntity>): EventProgramEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerDeleteProgram()` */
  static readonly EventControllerDeleteProgramPath = '/api/v1/events/programs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerDeleteProgram()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerDeleteProgram$Response(params: EventControllerDeleteProgram$Params, context?: HttpContext): Observable<StrictHttpResponse<EventProgramEntity>> {
    return eventControllerDeleteProgram(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerDeleteProgram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventControllerDeleteProgram(params: EventControllerDeleteProgram$Params, context?: HttpContext): Observable<EventProgramEntity> {
    return this.eventControllerDeleteProgram$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventProgramEntity>): EventProgramEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerUpdateProgram()` */
  static readonly EventControllerUpdateProgramPath = '/api/v1/events/programs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerUpdateProgram()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdateProgram$Response(params: EventControllerUpdateProgram$Params, context?: HttpContext): Observable<StrictHttpResponse<EventProgramEntity>> {
    return eventControllerUpdateProgram(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerUpdateProgram$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventControllerUpdateProgram(params: EventControllerUpdateProgram$Params, context?: HttpContext): Observable<EventProgramEntity> {
    return this.eventControllerUpdateProgram$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventProgramEntity>): EventProgramEntity => r.body)
    );
  }

  /** Path part for operation `eventControllerUpdateProgramImage()` */
  static readonly EventControllerUpdateProgramImagePath = '/api/v1/events/programs/{id}/image';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventControllerUpdateProgramImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  eventControllerUpdateProgramImage$Response(params: EventControllerUpdateProgramImage$Params, context?: HttpContext): Observable<StrictHttpResponse<EventProgramEntity>> {
    return eventControllerUpdateProgramImage(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventControllerUpdateProgramImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  eventControllerUpdateProgramImage(params: EventControllerUpdateProgramImage$Params, context?: HttpContext): Observable<EventProgramEntity> {
    return this.eventControllerUpdateProgramImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<EventProgramEntity>): EventProgramEntity => r.body)
    );
  }

}
