import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject, OnInit, signal, WritableSignal
} from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import {
  TuiButton,
  TuiDialogContext,
  TuiIcon,
  TuiTextfieldOptionsDirective
} from '@taiga-ui/core';
import { EditEventProgramForm, AddEventProgramForm } from '../../forms';
import { EventProgramEntity } from '@core/api/models/event-program-entity';
import { tuiCreateTimePeriods } from '@taiga-ui/kit';
import { EventService } from '@core/api/services';
import { ReactiveFormsModule } from '@angular/forms';
import { of, switchMap, takeUntil } from 'rxjs';

import {
  TuiInputModule,
  TuiInputTimeModule,
  TuiTextareaModule
} from '@taiga-ui/legacy';
import { ImagePickerComponent } from '@shared/image-picker/image-picker.component';
import { BaseAbstractModal } from '../../../base.abstract.modal';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'event-program-component',
  templateUrl: './event-program.modal.html',
  styleUrls: ['./event-program.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TuiInputModule,
    TuiTextareaModule,
    TuiInputTimeModule,
    TuiButton,
    TuiTextfieldOptionsDirective,
    TuiIcon,
    ImagePickerComponent
  ]
})
export class EventProgramModal extends BaseAbstractModal implements OnInit {
  public form: AddEventProgramForm | EditEventProgramForm;
  private eventsService = inject(EventService);
  public timePeriod = tuiCreateTimePeriods();
  public image: WritableSignal<any> = signal(null);

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<
      boolean,
      {
        eventId: string;
        program: EventProgramEntity | null;
      }
    >
  ) {
    super();

  }

  ngOnInit() {
    this.showLoader.set(true);
    const { program } = this.data;

    if (!program) {
      this.form = new AddEventProgramForm();
      this.showLoader.set(false);
    }

    if (program) {
      this.form = new EditEventProgramForm({
        description: program.description || '',
        order: program.order || 0,
        shortDescription: program.shortDescription || '',
        time: program.time || '',
        title: program.title || ''
      });
      this.showLoader.set(false);
    }

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  get data(): {
    eventId: string;
    program: EventProgramEntity | null;
  } {
    return this.context.data;
  }

  toTimeString(min: number, sec: number) {
    return `${
      min.toString().length === 1 ? '0' + min.toString() : min.toString()
    }:${sec.toString().length === 1 ? sec.toString() + '0' : sec.toString()}`;
  }

  pickImage(image: any) {
    this.image.set(image);
  }

  public onSubmit() {
    const { program } = this.data;
    console.log(this.form.value);
    if (!this.form.value) {
      return;
    }

    const time: any = Object.values(this.form.value.time!);

    if (!program) {
      this.eventsService
        .eventControllerCreateProgram({
          eventId: this.data.eventId,
          body: {
            description: this.form.value.description!,
            order: this.form.value.order!,
            shortDescription: this.form.value.shortDescription!,
            time: this.toTimeString(time[0], time[1]),
            title: this.form.value.title!
          }
        })
        .pipe(
          switchMap((res) =>
            this.image() && res?.id
              ? this.eventsService.eventControllerUpdateProgramImage({
                  id: res?.id,
                  body: { image: this.image()}
                })
              : of(res)
          ),
          takeUntil(this.destroy$),
          finalize(() => this.showLoader.set(false))
        )
        .subscribe((res) => this.context.completeWith(true));
    }

    if (program) {
      this.eventsService
        .eventControllerUpdateProgram({
          id: this.data.program?.id!,
          body: {
            ...this.form.value,
            time: this.toTimeString(time[0], time[1])
          }
        })
        .pipe(
          switchMap((res) =>
            this.image() && res?.id
              ? this.eventsService.eventControllerUpdateProgramImage({
                  id: res?.id,
                  body: { image: this.image() }
                })
              : of(res)
          ),
          takeUntil(this.destroy$),
          finalize(() => this.showLoader.set(false))
        )
        .subscribe((res) => this.context.completeWith(true));
    }
  }

  public onCancel() {
    this.context.completeWith(false);
  }
}
