import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject,
  OnInit
} from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { TuiButton, TuiDialogContext, TuiIcon, TuiLabel, TuiLoader } from '@taiga-ui/core';
import { PaymentSettingsEntity } from '@core/api/models/payment-settings-entity';
import { PaymentSettingsForm } from '../../forms/payment-settings.form';
import { PaymentsService } from '@core/api/services/payments.service';
import { takeUntil } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BaseAbstractModal } from '../../../base.abstract.modal';
import { ReactiveFormsModule, Validators } from '@angular/forms';

import { TuiCheckbox } from '@taiga-ui/kit';
import { TuiInputModule, TuiSelectModule } from '@taiga-ui/legacy';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-payment-settings',
  templateUrl: './payment-settings.modal.html',
  styleUrl: './payment-settings.modal.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TuiIcon,
    TuiLoader,
    ReactiveFormsModule,
    TuiLabel,
    TuiCheckbox,
    TuiInputModule,
    TuiSelectModule,
    TranslateModule,
    TuiButton
  ],
  standalone: true
})
export class PaymentSettingsModal extends BaseAbstractModal implements OnInit {

  public form = new PaymentSettingsForm({
    name: '',
    platform: 'liqpay',
    active: true,
    liqpaySettings: {
      privateKey: '',
      publicKey: '',
      paymentId: ''
    }
  });

  private paymentsService = inject(PaymentsService);
  public platforms = ['liqpay'];

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<
      boolean,
      {
        payment: PaymentSettingsEntity | null;
      }
    >
  ) {
    super();
  }

  get data(): {
    payment: PaymentSettingsEntity | null;
  } {
    return this.context.data;
  }

  ngOnInit() {
    this.showLoader.set(true);
    const { payment } = this.data;

    if(!payment) {
      this.showLoader.set(false);
      return;
    }

    this.paymentsService
      .paymentControllerFindOne({ id: payment.id })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.showLoader.set(false))
      )
      .subscribe((res) => {
        this.form.setValue({
          name: res.name,
          platform: res.platform,
          active: res.active,
          liqpaySettings: res.liqpaySettings ? {
            privateKey: res.liqpaySettings.privateKey,
            publicKey: res.liqpaySettings.publicKey
          } : {
            privateKey: '',
            publicKey: ''
          }
        });
      });
  }

  public onSubmit() {
    this.showLoader.set(true);
    const { payment } = this.data;
    if (!this.form.value) {
      this.showLoader.set(false);
      return;
    }

    const data: any = {
      active: this.form.value.active!,
      platform: this.form.value.platform!,
      name: this.form.value.name!
    };

    if (this.form.value.liqpaySettings) {
      data.liqpaySettings = this.form.value.liqpaySettings!;
    }

    if (!payment) {
      this.paymentsService
        .paymentControllerCreate({
          body: data
        })
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.showLoader.set(false)))
        .subscribe((res) => this.context.completeWith(true));
      return;
    }

    this.paymentsService
      .paymentControllerUpdate({
        id: payment.id,
        body: data
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.showLoader.set(false)))
      .subscribe((res) => this.context.completeWith(true));
  }

  public onCancel() {
    this.context.completeWith(false);
  }
}
