/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EventEntity } from '../../models/event-entity';

export interface EventControllerFindByCompany$Params {
  limit: number;
  page: number;
  title?: string;
  type?: 'ONLINE' | 'PHYSICAL';
  status?: 'STAND_BY' | 'PUBLIC';
  includeArtists?: boolean;
  includeCategories?: boolean;
  includeLinks?: boolean;
  includePartners?: boolean;
  includePrograms?: boolean;
}

export function eventControllerFindByCompany(http: HttpClient, rootUrl: string, params: EventControllerFindByCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<EventEntity>;
}>> {
  const rb = new RequestBuilder(rootUrl, eventControllerFindByCompany.PATH, 'get');
  if (params) {
    rb.query('limit', params.limit, {});
    rb.query('page', params.page, {});
    rb.query('title', params.title, {});
    rb.query('type', params.type, {});
    rb.query('status', params.status, {});
    rb.query('includeArtists', params.includeArtists, {});
    rb.query('includeCategories', params.includeCategories, {});
    rb.query('includeLinks', params.includeLinks, {});
    rb.query('includePartners', params.includePartners, {});
    rb.query('includePrograms', params.includePrograms, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'total'?: number;
      'limit'?: number;
      'page'?: number;
      'items'?: Array<EventEntity>;
      }>;
    })
  );
}

eventControllerFindByCompany.PATH = '/api/v1/events';
