import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { CompanyEntity } from '@core/api/models/company-entity';
import { NgStyle } from '@angular/common';
import { TuiAvatar } from '@taiga-ui/kit';
import { ImageUrlPipe } from '@core/pipes/image-url.pipe';

@Component({
  selector: 'app-company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss'],
  imports: [TuiAvatar],
  standalone: true
})
export class CompanyCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() company: CompanyEntity;
  @Output() selectCompany?: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy(): void {}
}
