<tui-loader [overlay]="true" [showLoader]="showLoader()">
  <div tuiCardLarge tuiSurface="elevated" [formGroup]="createForm">
    <div class="flex flex-wrap mb-4">
      <div class="w-full">
        <button
          tuiButton
          size="m"
          appearance="secondary"
          (click)="back()"
          class="back-btn"
        >
          <tui-icon icon="@tui.chevron-left"></tui-icon>
          {{ 'common#back' | translate }}
        </button>
      </div>
    </div>

    <div class="mb-4">
      <h1 class="text-3xl font-bold">{{ 'company#create_edit#title' | translate }}</h1>
      <hr class="mt-2">
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
      <div>
        <h2 class="text-2xl font-semibold mb-4">{{ 'company#create_edit#logo#titile' | translate }}</h2>
        <p>{{ 'company#create_edit#logo#description' | translate }}</p>
      </div>
      <div>
        <app-image-picker (selectImage)="pickImage($event)"></app-image-picker>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
      <div>
        <h2 class="text-2xl font-semibold mb-4">{{ 'company#create_edit#info#titile' | translate }}</h2>
        <p>{{ 'company#create_edit#info#description' | translate }}</p>
      </div>
      <div>
        <tui-input class="mb-4 field-large" formControlName="name">
          {{ 'company#form#name' | translate }}
          <input tuiTextfieldLegacy type="text" />
        </tui-input>
        <tui-input class="mb-4 field-large" formControlName="email">
          {{ 'company#form#email' | translate }}
          <input tuiTextfieldLegacy type="email" />
        </tui-input>
        <tui-input formControlName="specialization" class="mb-4 field-large">
          {{ 'company#form#specialization' | translate }}
        </tui-input>
      </div>
    </div>
    <hr class="mb-4">

    <div class="flex justify-end space-x-3">
      <button
        tuiButton
        type="button"
        appearance="outline"
        (click)="back()"
        class="mb-3"
      >
        {{ 'common#cancel' | translate }}
      </button>
      <button
        tuiButton
        type="button"
        class="mb-3"
        (click)="createCompany()"
        [disabled]="createForm.invalid"
      >
        {{ 'common#create' | translate }}
      </button>
    </div>
  </div>
</tui-loader>
