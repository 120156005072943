<tui-loader [overlay]="true" [showLoader]="showLoader()">
  @if (event$ | async; as event) {
    <div>
      <!-- Event Header Section -->
      <div class="relative">
        <!-- Background Image with Blur Effect -->
        <div
          class="bg-cover bg-center h-64"
          [ngStyle]="{'background-image': 'url(' + (event?.info?.mainImg) + ')'}"
        >
          <div class="absolute inset-0 bg-black opacity-30 backdrop-blur"></div>
        </div>
        <!-- Header Content Overlay -->
        <div class="absolute inset-0 flex items-center p-4">
          <!-- Image Holder -->
          <div class="w-24 h-24 rounded-full overflow-hidden mr-4">
            <img
              [src]="event.info?.mainImg"
              alt=""
              class="object-cover w-full h-full"
            >
          </div>
          <!-- Event Information -->
          <div class="flex-1">
            <div class="flex flex-wrap">
              <!-- Left Column: Title and Date -->
              <div class="w-full md:w-1/2">
                <h2 class="text-3xl font-bold mb-1">
                  {{ event.info?.title }}
                </h2>
                <div class="flex items-center space-x-2">
                  <div class="text-sm text-gray-500">
                    {{ event.startDateTime | date }} - {{ event.startDateTime | date }}
                  </div>
                  <tui-tag
                    size="l"
                    status="custom"
                    class="tag"
                    [value]="event.status"
                  ></tui-tag>
                </div>
              </div>
              <!-- Right Column: Button -->
              <div class="w-full md:w-1/2 text-right">
                <div>
                  <button
                    tuiButton
                    type="button"
                    appearance="secondary"
                    class="ml-4"
                    size="l"
                    (click)="showEvent()"
                  >
                    Show event
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tabs Section -->
      <tui-tabs class="mb-10" [(activeItemIndex)]="activeItemIndex">
        <button tuiTab>
          Main Info
        </button>
        <button tuiTab>
          Program
        </button>
        <button tuiTab>
          Artists
        </button>
        <button tuiTab>
          Partners
        </button>
        <button tuiTab>
          Tickets
        </button>
      </tui-tabs>

      <!-- Conditional Tab Content Section -->
      <div class="tabs-holder">
        @if (activeItemIndex === 0) {
          <general-event-info-component
            [info]="event.info"
            (updateEventInfo)="loadEvent()"
          ></general-event-info-component>
        }
        @if (activeItemIndex === 1) {
          <event-program-list-component [eventId]="event.id"></event-program-list-component>
        }
        @if (activeItemIndex === 2) {
          <event-artists-list-component [eventId]="event.id"></event-artists-list-component>
        }
        @if (activeItemIndex === 3) {
          <event-partner-list-component [eventId]="event.id"></event-partner-list-component>
        }
        @if (activeItemIndex === 4) {
          <event-ticket-tiers-list-component [eventId]="event.id"></event-ticket-tiers-list-component>
        }
      </div>
    </div>
  }
</tui-loader>
