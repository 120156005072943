<tui-loader [overlay]="true" [showLoader]="showLoader()">
  <div class="container">
    <div class="flex flex-col md:flex-row -mx-3">
      <div class="w-full hidden md:block md:w-1/2 px-3">
        <div tuiCardLarge tuiSurface="elevated" class="with-bg w-full h-full"
             style="background-image: url('assets/auth-bg.jpg')">
          <div class="logo-holder">
            <img src="assets/single-logo-white.svg">
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 flex items-center justify-center px-3">
        <div tuiCardLarge tuiSurface="elevated" class="w-full h-full">
          <form class="w-full h-full" (ngSubmit)="singIn()" [formGroup]="loginForm">
            <div class="form-holder flex flex-col items-center text-center">
              <h1 class="mb-2 text-xl font-semibold">{{ 'auth#sign_in#title' | translate }}</h1>
              <p class="mb-8 text-gray-500">{{ 'auth#sign_in#description' | translate }}</p>
              <tui-input class="w-full mb-4" formControlName="email">
                {{ 'auth#sign_in#e-mail' | translate }}
              </tui-input>
              <tui-input-password class="w-full mb-6" formControlName="password">
                {{ 'auth#sign_in#password' | translate }}
              </tui-input-password>
              <footer class="w-full">
                <button
                  tuiButton
                  type="submit"
                  appearance="primary"
                  [disabled]="loginForm.invalid"
                  class="mb-4 w-full"
                >
                  {{ 'auth#sign_in#login' | translate }}
                </button>
                <div class="separate-line"><span>або</span></div>
                <!--              <button-->
                <!--                tuiButton-->
                <!--                type="button"-->
                <!--                appearance="outline"-->
                <!--                (click)="singInThirdParty('google')"-->
                <!--                class="sign-in-btn tui-space_bottom-2"-->
                <!--              >-->
                <!--                {{ 'auth#sign_in#login_via_google' | translate }}-->
                <!--              </button>-->
                <!--              <button-->
                <!--                tuiButton-->
                <!--                type="button"-->
                <!--                appearance="glass"-->
                <!--                (click)="singInThirdParty('apple')"-->
                <!--                class="sign-in-btn tui-space_bottom-7"-->
                <!--              >-->
                <!--                {{ 'auth#sign_in#login_via_apple' | translate }}-->
                <!--              </button>-->
                <p class="tui-island_text-align_center"
                   [innerHTML]="('auth#sign_in#not_have_account' | translate : {link: '/auth/sign-up'})">
                </p>
              </footer>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</tui-loader>
