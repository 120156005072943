<tui-loader [overlay]="true" [showLoader]="showLoader()">
  <!-- Modal Container -->
  <div class='modal-content'>
    <div class='description'>
      <div class='description_holder'>
        <div class='description_holder_icon'>
          <tui-icon icon='@tui.file-text'></tui-icon>
        </div>
      </div>
      <h3 class='tui-text_h3 tui-space_bottom-4'>Що таке пункти програми і як створити повну програму?</h3>
      <p>
        Пункти програми це розділи розкладу вашого івенту. Наприклад з 17:00 до 18:00 у вас буде виступати гурт для
        розігріву глядачів, а з 18:00 до 20:00 хейдлайнери вашого концерту.
        <br>
        Кожен пункт програми потрібно додавати окрему для гармонічного відображення на сторінці вашого івенту.
        <br>
        Будь-лазка заповніть усі поля справа та натисніть створити, після чого пункт будет доданий до вашої програми.
      </p>
    </div>

    @if (form) {
      <!-- Form Section -->
      <div class='form-holder' [formGroup]="form">
        <h2 class='tui-text_h2 tui-space_bottom-8'>Створити пункт програми</h2>
        <p>Картинка пункту (рекомендований розмір 256х256px)</p>
        <app-image-picker
          [existImageUrl]="data?.artist?.imgUrl"
          (selectImage)="pickImage($event)"
        ></app-image-picker>

        <tui-input class="mb-4" formControlName="name">
          Ім’я учасника
        </tui-input>

        <tui-textarea
          formControlName="description"
          class="mb-4"
          [maxLength]="120"
          [expandable]="false"
        >
          Опис
        </tui-textarea>

        <tui-input-number class="mb-4" formControlName="order">
          Order on view
        </tui-input-number>

        <tui-input class="mb-4" formControlName="type">
          Тип учасника
        </tui-input>

        <ng-container formArrayName="links">
          @for (link of links.controls; track link; let i = $index) {
            <div class="flex items-center mb-4">
              <tui-input class="flex-1 mb-0" [formControlName]="i">
                Лінк до соціальних мереж
              </tui-input>
              <tui-icon
                class="ml-2 cursor-pointer"
                icon="@tui.trash-2"
                (click)="form.removeLink(i)"
              ></tui-icon>
            </div>
          }
        </ng-container>

        <button
          class="mb-4"
          tuiButton
          type="button"
          appearance="secondary-destructive"
          size="s"
          (click)="form.addLink()"
        >
          <tui-icon icon="@tui.plus"></tui-icon>
          Add link
        </button>

        <!-- Modal Footer with Action Buttons -->
        <div class="flex justify-end space-x-4 mt-6">
          <button
            tuiButton
            type="button"
            appearance="outline"
            (click)="onCancel()"
          >
            Cancel
          </button>
          <button
            tuiButton
            type="button"
            (click)="onSubmit()"
            [disabled]="form.invalid"
          >
            Save
          </button>
        </div>
      </div>
    }
  </div>
</tui-loader>
