import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormGroupDef } from '@core/utils';
import { CreateEventDto } from '@core/api/models/create-event-dto';
import { CreateEventInfoDto } from '@core/api/models/create-event-info-dto';

export class AddEventForm extends FormGroup<FormGroupDef<CreateEventDto>> {
  constructor() {
    super({
      ageRating: new FormControl(0, { nonNullable: true, validators: [Validators.required, Validators.min(0)] }),
      endDateTime: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      eventInfo: new FormGroup<FormGroupDef<CreateEventInfoDto>>({
        description: new FormControl<string | null>(null),
        publicTitle: new FormControl('', { nonNullable: true, validators: [Validators.required] })
      }),
      isDraft: new FormControl(false, { nonNullable: true }),
      smartTickets: new FormControl<'NONE' | 'ONLY' | 'ANY'>('NONE', {
        nonNullable: true,
        validators: [Validators.required]
      }),
      startDateTime: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      status: new FormControl<'STAND_BY' | 'PUBLIC'>('STAND_BY', {
        nonNullable: true,
        validators: [Validators.required]
      }),
      tags: new FormArray<FormControl<string>>([]),
      ticketsAmount: new FormControl(0, { nonNullable: true, validators: [Validators.required, Validators.min(0)] }),
      ticketsAmountVacant: new FormControl(0, {
        nonNullable: true,
        validators: [Validators.required, Validators.min(0)]
      }),
      title: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
      type: new FormControl<'ONLINE' | 'PHYSICAL'>('ONLINE', { nonNullable: true, validators: [Validators.required] })
    });
  }
}
