import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { AuthCompanyService } from '@core/services/auth-company.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private router = inject(Router);
  private authService = inject(AuthService);
  private authCompanyService = inject(AuthCompanyService);

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {

    const doesSessionExist = await firstValueFrom(this.authService.token$);

    if (!doesSessionExist) {
      await this.router.navigateByUrl('/auth');
      return false;
    }

    const company = this.authCompanyService.current();

    if (doesSessionExist && !company && next?.routeConfig?.path !== 'companies') {
      await this.router.navigateByUrl(`/companies`);
      return false;
    }

    return true;
  }
}
