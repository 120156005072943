/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CompanyMemberEntity } from '../models/company-member-entity';
import { companyMembersControllerCreate } from '../fn/company-member/company-members-controller-create';
import { CompanyMembersControllerCreate$Params } from '../fn/company-member/company-members-controller-create';
import { companyMembersControllerGetAll } from '../fn/company-member/company-members-controller-get-all';
import { CompanyMembersControllerGetAll$Params } from '../fn/company-member/company-members-controller-get-all';
import { companyMembersControllerGetByUser } from '../fn/company-member/company-members-controller-get-by-user';
import { CompanyMembersControllerGetByUser$Params } from '../fn/company-member/company-members-controller-get-by-user';
import { companyMembersControllerGetRoles } from '../fn/company-member/company-members-controller-get-roles';
import { CompanyMembersControllerGetRoles$Params } from '../fn/company-member/company-members-controller-get-roles';
import { companyMembersControllerRemove } from '../fn/company-member/company-members-controller-remove';
import { CompanyMembersControllerRemove$Params } from '../fn/company-member/company-members-controller-remove';

@Injectable({ providedIn: 'root' })
export class CompanyMemberService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `companyMembersControllerGetAll()` */
  static readonly CompanyMembersControllerGetAllPath = '/api/v1/company-members';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyMembersControllerGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyMembersControllerGetAll$Response(params: CompanyMembersControllerGetAll$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<CompanyMemberEntity>;
}>> {
    return companyMembersControllerGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyMembersControllerGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyMembersControllerGetAll(params: CompanyMembersControllerGetAll$Params, context?: HttpContext): Observable<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<CompanyMemberEntity>;
}> {
    return this.companyMembersControllerGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<CompanyMemberEntity>;
}>): {
'total'?: number;
'limit'?: number;
'page'?: number;
'items'?: Array<CompanyMemberEntity>;
} => r.body)
    );
  }

  /** Path part for operation `companyMembersControllerCreate()` */
  static readonly CompanyMembersControllerCreatePath = '/api/v1/company-members';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyMembersControllerCreate()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyMembersControllerCreate$Response(params: CompanyMembersControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyMemberEntity>> {
    return companyMembersControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyMembersControllerCreate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyMembersControllerCreate(params: CompanyMembersControllerCreate$Params, context?: HttpContext): Observable<CompanyMemberEntity> {
    return this.companyMembersControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyMemberEntity>): CompanyMemberEntity => r.body)
    );
  }

  /** Path part for operation `companyMembersControllerGetByUser()` */
  static readonly CompanyMembersControllerGetByUserPath = '/api/v1/company-members/user/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyMembersControllerGetByUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyMembersControllerGetByUser$Response(params: CompanyMembersControllerGetByUser$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyMemberEntity>> {
    return companyMembersControllerGetByUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyMembersControllerGetByUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyMembersControllerGetByUser(params: CompanyMembersControllerGetByUser$Params, context?: HttpContext): Observable<CompanyMemberEntity> {
    return this.companyMembersControllerGetByUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyMemberEntity>): CompanyMemberEntity => r.body)
    );
  }

  /** Path part for operation `companyMembersControllerRemove()` */
  static readonly CompanyMembersControllerRemovePath = '/api/v1/company-members/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyMembersControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyMembersControllerRemove$Response(params: CompanyMembersControllerRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyMemberEntity>> {
    return companyMembersControllerRemove(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyMembersControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyMembersControllerRemove(params: CompanyMembersControllerRemove$Params, context?: HttpContext): Observable<CompanyMemberEntity> {
    return this.companyMembersControllerRemove$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyMemberEntity>): CompanyMemberEntity => r.body)
    );
  }

  /** Path part for operation `companyMembersControllerGetRoles()` */
  static readonly CompanyMembersControllerGetRolesPath = '/api/v1/company-members/roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyMembersControllerGetRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyMembersControllerGetRoles$Response(params?: CompanyMembersControllerGetRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return companyMembersControllerGetRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyMembersControllerGetRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyMembersControllerGetRoles(params?: CompanyMembersControllerGetRoles$Params, context?: HttpContext): Observable<Array<string>> {
    return this.companyMembersControllerGetRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
