<app-page-header
  [title]="'events#list#title' | translate"
  [description]="'events#list#description' | translate"
  [actionTitle]="'events#list#add_btn' | translate"
  (action)="router.navigate(['events/create'])"
></app-page-header>

<tui-loader [overlay]="true" [showLoader]="showLoader()">
  <!-- Events subtitle -->
  <h2 class="text-2xl font-semibold mb-4">{{ 'events#list#sub_title' | translate }}</h2>

  <!-- Events grid container -->
  <div class="grid grid-cols-[repeat(auto-fit,minmax(0,297px))] gap-4">
    @for (event of data(); track event) {
      <!-- Single event card -->
      <div>
        <event-card-component (eventClick)="viewEvent(event.id)" [event]="event"></event-card-component>
      </div>
    }
  </div>
</tui-loader>
