import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  type TuiFileLike,
  TuiFiles,
} from '@taiga-ui/kit';
import { Observable, of, Subject, timer } from 'rxjs';
import { ImageUrlPipe } from '@core/pipes/image-url.pipe';
import { ObjectUrlPipe } from '@core/pipes/object-url.pipe';
import { finalize, map, switchMap } from 'rxjs/operators';
import { TuiButton } from '@taiga-ui/core';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    TuiFiles,
    ObjectUrlPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePickerComponent implements OnInit {
  @Input() title?: string;
  @Input() description?: string;
  @Input() existImageUrl?: string | null = null;
  @Output() selectImage: EventEmitter<any> = new EventEmitter();

  protected readonly control = new FormControl<TuiFileLike | null>(null, Validators.required);
  protected readonly failedFiles$ = new Subject<TuiFileLike | null>();
  protected readonly loadingFiles$ = new Subject<TuiFileLike | null>();
  protected readonly loadedFiles$ = this.control.valueChanges.pipe(
    switchMap((file) => this.processFile(file)),
  );

  constructor() {
  }

  ngOnInit() {
  }

  protected processFile(file: TuiFileLike | null): Observable<TuiFileLike | null> {
    this.failedFiles$.next(null);

    if (this.control.invalid || !file) {
      return of(null);
    }

    this.loadingFiles$.next(file);

    return timer(300).pipe(
      map(() => {
          this.selectImage.emit(file);
          return file;
      }),
      finalize(() => this.loadingFiles$.next(null)),
    );
  }

  protected removeFile(): void {
    this.control.setValue(null);
  }

}
