<app-page-header
  [title]="'payment#list#title' | translate"
  [description]="'payment#list#description' | translate"
  [actionTitle]="'payment#list#add_btn' | translate"
  (action)="add()"
></app-page-header>

<tui-loader [overlay]='true' [showLoader]='showLoader()'>
  @if (paymentsSettings$ | async; as payments) {
    @if (payments?.items.length !== 0) {
      <div tuiCardLarge tuiSurface="elevated">
        <table
          tuiTable
          class='table'
          [columns]='displayedColumns'
        >
          <thead>
          <tr tuiThGroup>
            <th tuiTh>{{ 'payment#form#name' | translate }}</th>
            <th tuiTh>{{ 'payment#form#platform' | translate }}</th>
            <th tuiTh>{{ 'payment#form#active' | translate }}</th>
            <th tuiTh>{{ 'payment#table#dates' | translate }}</th>
            <th tuiTh>{{ 'common#table#actions' | translate }}</th>
          </tr>
          </thead>
          <tbody
            tuiTbody
            [data]='payments?.items'
          >
            @for (item of payments?.items; track item) {
              <tr
                tuiTr
              >
                <td
                  *tuiCell="'name'"
                  tuiTd
                >
                  {{ item.name || '-' }}
                </td>
                <td
                  *tuiCell="'platform'"
                  tuiTd
                >
                  {{ item.platform || '-' }}
                </td>
                <td
                  *tuiCell="'active'"
                  tuiTd
                >
                  {{ item?.active || '-' }}
                </td>
                <td
                  *tuiCell="'dates'"
                  tuiTd
                >
                  <span>{{ 'payment#table#update_date' | translate }}:</span>
                  <span>{{ (item?.updatedAt | date) || '-' }}</span>
                  <br>
                  <span>{{ 'payment#table#create_date' | translate }}:</span>
                  <span>{{ (item?.createdAt | date) || '-' }}</span>
                </td>
                <td
                  *tuiCell="'actions'"
                  tuiTd
                >
                  <tui-icon class='icon-button' (click)='add(item)' icon="@tui.pencil"></tui-icon>
                </td>
              </tr>
            }
          </tbody>
        </table>
        <tui-pagination
          [index]="currentPage - 1"
          [length]="pages(payments?.totalPages)"
          (indexChange)="onPage($event)"
        ></tui-pagination>
      </div>
    }
  }
</tui-loader>
