import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError, tap, switchMap, firstValueFrom } from 'rxjs';
import { AuthApiService } from '@core/auth-api/services/auth-api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authApiService = inject(AuthApiService);

  private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public user$: Observable<any> = this.userSubject.asObservable();
  private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(localStorage.getItem('access_token') || null);
  public token$: Observable<any> = this.tokenSubject.asObservable();

  async init() {
    await this.getUserFromSession();
  }

  async getUserFromSession() {
    const token = localStorage.getItem('access_token');
    if (token) {
      const user = await firstValueFrom(this.getMe());
      if (user) {
        this.updateUser(user);
        return;
      }
      this.logout();

    }
  }

  login(email: string, password: string): Observable<any> {
    return this.authApiService.authControllerLogin(
      {
        body: { email, password }
      }
    ).pipe(
      tap((response: any) => {
        const data = JSON.parse(response);
        this.storeTokens(data.access_token, data.refresh_token);
      }),
      switchMap((token: any) => {
        return this.getMe();
      }),
      map((user => this.updateUser(user))),
      catchError(err => this.handleError(err))
    );
  }

  register(email: string, password: string): Observable<any> {
    return this.authApiService.authControllerRegister({
      body: { email, password }
    }).pipe(
      tap((response: any) => {
        this.storeTokens(response.access_token, response.refresh_token);
        this.getMe().subscribe(user => this.updateUser(user));
      }),
      catchError(err => this.handleError(err))
    );
  }

  getMe(): Observable<any> {
    return this.authApiService.authControllerGetMe().pipe(
      catchError(err => this.handleError(err))
    );
  }

  logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    this.userSubject.next(null);
  }

  signInWithGoogle() {
    // window.location.href = `${this.apiUrl}/google`;
  }

  private handleError(error: any): Observable<never> {
    let errorMessage = 'Oops! Something went wrong.';
    if (error.error?.message) {
      errorMessage = error.error.message;
    }
    return throwError(() => new Error(errorMessage));
  }

  private updateUser(user: any): void {
    this.userSubject.next(user);
  }

  private storeTokens(accessToken: string, refreshToken: string) {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
    this.tokenSubject.next(accessToken);
  }
}
